<template>
        <v-card class="mb-2" dense>
            <v-card-title>
                Inleidende tekst
            </v-card-title>
            <v-card-subtitle class="opacity-40">
                Optioneel. Wanneer ingevuld wordt deze tekst ingevoegd boven de offerteregels.
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text>
                <v-row dense>
                    <v-col sm="12" xl="6">
                        <RichTextArea :disabled="model.isOpenOrLater" xl :model='model' placeholder='Offerte brief' v-model='model.tend_letter'>
                            <template v-slot:toolbar-cmd-right="{editor}">
                                <span class="ml-4">Begin brief op nieuwe pagina</span>
                                <span class="mt-2 mr-2 ml-4">
                                    <Checkbox :disabled="model.isOpenOrLater" class="d-inline-block width-auto" switcher v-model="model.tend_letter_new_page"></Checkbox>
                                </span>
                            </template>
                            <template v-slot:toolbar-right="{editor}">
                                <ActionButton icon="text" class="mr-2" sub action="tender.modify" dusk="btn-select-tender-letter" :model="model" ml :disabled="model.isOpenOrLater"  @click='()=>addStandardText(editor)'>Standaard Tekst</ActionButton>
                            </template>
                        </RichTextArea>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
</template>



<script setup>
    
    import RichTextArea from '@controls/input/RichTextArea'
    import ActionButton from '@controls/buttons/ActionButton'
    import Checkbox from '@controls/check/Checkbox.vue'

    import dlg from '@app/dlg'
    const props = defineProps({
        model: {
            type: [Object]
        },
    });

    async function addStandardText(editor) {
        let selected = null;
        try { 
            selected = await dlg.promised.open("select_standardtext", {multiselect:false})
            if (!selected || !selected.length) {
                return;
            }
        } catch (e) { return; }  // canceled
        if (selected && selected.length && selected[0].text) {
            console.log("Standaard text: ", selected);
            editor.chain().focus().insertContent(selected[0].text).run()
        }
    }

</script>

<template>
    <Dialog :dialog="dlg" :dt="dt">

        <template v-slot:filter>
            <v-row dense>
                <v-col><TextField dusk="select-search-q" ref="q" block max-length="255" placeholder="Bedrijfsnaam" v-model="dt.filter.q"></TextField></v-col>
                <v-col><TextField dusk="select-search-city" block max-length="255" placeholder="Plaats" v-model="dt.filter.city"></TextField></v-col>
                <v-col><TextField dusk="select-search-kvk" block max-length="255" placeholder="KVK nummer" v-model="dt.filter.kvk"></TextField></v-col>
            </v-row>

        </template>
    </Dialog>
</template>



<script setup>

    import useDataTable from '@app/useDataTable'
    import string from '@lib/string'

    import Form from '@controls/forms/Form'
    import FormColumn from '@controls/forms/FormColumn'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/SelectListDialog'
    import { kvk as api } from '@app/api'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsSelectListDialog'
    import Constants from '@app/consts'

    const form = ref(null); 
    const q = ref(null);

    var headers = [
        { type: 'cmd'},
    	{ text: "Bedrijfsnaam",    value: "name"      }, 
    	{ text: "Vestigingstype",  value: "type",     visible: false}, 
		{ text: "KVK Nummer",      value: "kvk"       }, 
		{ text: "Plaats",          value: "city"      }, 
		{ text: "Adres",           value: "street",   }, 
        
        
//		{ text: "Postcode", value: "zipcode",  xvisible: false}, 

    ];

    var dt = useDataTable("dt_selectkvk", "kvk", api, headers, {
        actionDblClick:null,
        itemName: {
            prefix: 'de',
            single: 'onderneming',
            plural: 'ondernemingen',
        }, 
        useCommandPane: false,
        canArchive: false,
        filter: {
            q: '',
            city: '',
            kvk: '',
        }
    })

    async function onBeforeResolve(items) {
        if (!items || !items.length) {
            return [];
        }
        let item = items[0];
        if (!item || !item.kvk) {
            return []
        }
        dt.loading = true;
        try {
            let result = await api.load(item.kvk)
            if (!result || !result.data) {
                return []
            }
            // All select dialogs return arrays, lets keep it that way although we return just one.
            return [result.data];            
        } catch (e) {
            console.error(e);
            return [];
        } finally {
            dt.loading = false;
        }

    }


    let dlg = ref(
        new clsDialog("select_kvk", "Zoek binnen KVK bestand ", dt, {
            multiselect: false, 
            maxWidth:"1200px",
            defaultFocus: ()=>q.value,
            onBeforeResolve: onBeforeResolve
        })).value;

        window.kdlg = dlg;
</script>

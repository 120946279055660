<template>
    <span :class="{ 'error--text': error }">{{ rep }}</span>
</template>


<script setup>
/**
 * import Date from from '@datatable/columns/Date'
 * 
 *     <template v-slot:item.date="{ item }">
 *       <Date  v-model="item.date"></Date>
 * or
 *       <Date :value="item.date" warn-past></Date>
 *     </template>
 * 
 */

    import { date } from "@lib/date";
    import {computed, ref} from 'vue'

    const props = defineProps({
        value: {
            type: [String, Date]
        },
        warnPast: {
            type: Boolean,
        },
    })
    const error = computed({
        get() {
            if (!props.warnPast) {
                return false;
            }
            if (date.isInPast(props.value)) {
                return true;
            }
            return false;
        },
    })
    const rep = computed({
        get() {
            if (!props.value) {
                return "";
            }
            return date.fmt.local(props.value);
        },
    })

</script>


/**
 * Export all lists which are used in the application. 
 * Since lists are subscribed to data load events, we need to have all lists available at application start.
 * 
 * An instance of this class is returned by @app/list so that lists can be used as follows: 
 * 
 * import {company} from '@app/list'
 * 
 * var companies = company.list <-- when added in this class.
 *
 * 
 */

import clsList from '@cls/clsList'
import clsListCurrency from '@cls/list/currency'
import clsListVat from '@cls/list/vat'
import clsListRelation from '@cls/list/relation'
import clsProjectPhase from '@/app/list/projectphase'

import fnExpose from '@lib/expose'
import {ref} from 'vue'

const currency              = ref(new clsListCurrency({modelName: "currency"})).value;
const vat                   = ref(new clsListVat({modelName: "vat"})).value;
const relation              = ref(new clsListRelation({modelName: "relation"})).value;
const country               = ref(new clsList({modelName: "country"})).value;
const language              = ref(new clsList({modelName: "language"})).value;
const unit                  = ref(new clsList({modelName: "unit"})).value;
const creditrestriction     = ref(new clsList({modelName: "creditrestriction"})).value;
const employee              = ref(new clsList({modelName: "employee"})).value;
const ledger                = ref(new clsList({modelName: "ledger"})).value;
const supplier              = ref(new clsList({modelName: "supplier"})).value;
const productgroup          = ref(new clsList({modelName: "productgroup"})).value;
const department            = ref(new clsList({modelName: "department"})).value;
const jobtitle              = ref(new clsList({modelName: "jobtitle"})).value;
const costtype              = ref(new clsList({modelName: "costtype"})).value;
const hourtype              = ref(new clsList({modelName: "hourtype"})).value;
const dispute               = ref(new clsList({modelName: "dispute"})).value;
const projectphase          = ref(new clsProjectPhase({modelName: "projectphase"})).value;
const projectgroup          = ref(new clsList({modelName: "projectgroup"})).value;
const tenderstatus          = ref(new clsList({modelName: "tenderstatus"})).value;
const widget                = ref(new clsList({modelName: "widget"})).value;
const workordertype         = ref(new clsList({modelName: "workordertype"})).value;
const absencetype           = ref(new clsList({modelName: "absencetype"})).value;
const tendergroup           = ref(new clsList({modelName: "tendergroup"})).value;
const companyuser           = ref(new clsList({modelName: "companyuser"})).value;
const extraworkstatus       = ref(new clsList({modelName: "extraworkstatus"})).value;

fnExpose({currency, vat, country, language, unit, creditrestriction, relation, employee, ledger, supplier, productgroup, department, jobtitle, costtype, dispute, projectphase, tenderstatus, widget, workordertype, absencetype, tendergroup, companyuser, projectphase, projectgroup, extraworkstatus, hourtype}, 'list')

export {
    currency            ,
    country             ,
    language            ,
    vat                 ,
    unit                , 
    creditrestriction   ,
    relation            ,
    employee            ,
    ledger              , 
    supplier            ,
    productgroup        ,
    department          ,
    jobtitle            ,
    costtype            ,
    hourtype            ,
    dispute             ,
    projectphase        , 
    projectgroup        , 
    tenderstatus        ,
    widget              ,
    workordertype       ,
    absencetype         ,
    tendergroup         ,
    companyuser         ,
    extraworkstatus     ,
} 
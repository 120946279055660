<template>
    <Dialog :dialog="dlg" xno-sub-title no-content-padding>

        <Datatable :dt="dt" class="full-height">
            <template v-slot:menu ="{ item }">
              <ActionMenuItem :action="`tender.open`" @click="appDlg.open('tender', item)"></ActionMenuItem>
            </template>

            <template v-slot:item.status="{ item }">
                <StatusChip type="tender" :status="chipStatus(item)">{{statusRep(item)}}</StatusChip>
            </template>

            <template v-slot:item.tend_number="{ item }">
                <span class="cursor-pointer text-primary" @click="()=>onOpen(item)">{{ item.tend_number }}</span>
            </template>

        </Datatable>

    </Dialog>
</template>



<script setup>
    import useDataTable from '@app/useDataTable'
    import Datatable from '@datatable/Datatable'
    import { tender_versions as api } from '@app/api'
    import { ref } from 'vue'
    import {date} from '@lib/date'
    import clsDialog from '@cls/clsDialog'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import StatusChip from "@controls/widgets/StatusChip.vue";
    import Constants from '@app/consts'
    import {tenderstatus} from '@app/list';
    import ActionMenuItem from '@controls/menu/ActionMenuItem.vue'
    import appDlg from '@app/dlg'
  

    var headers = [
      { type: "cmd",},
      { text: "Status",                   value: "status",      },
      { text: "Offertenummer",            value: "tend_number" } ,
      { visible: true, text: "Aangemaakt",               value: "created_at",    type: 'datetime',},
      { visible: true, text: "Aangemaakt door",          value: "created_by",                     },
      { visible: false, text: "Verkoper",                 value: "company_user_contact"} ,
      { visible: false, text: "Klant",                    value: "rel_name"} ,
      { visible: false, text: "Telefoon",                 value: "rel_phone", type: 'phone' },
      { text: "Offertedatum",             value: "tend_date",    type: 'date'   },
      { visible: false, text: "Vervaldatum", value: "tend_exp_date", type: 'date'},
      { text: "Referentie",               value: "tend_reference", emptyText: '-'} ,
      { visible: false, text: "Offerte titel",            value: "tend_name",        },
      { visible: false, text: "Project naam",                  value: "pro_name",  emptyText:'-', } ,
      { visible: false, text: "Project Ref",              value: "pro_reference",  emptyText:'-', } ,
      { text: "Project",                  value: "pro_number",  emptyText:'-', } ,

      { visible: false, text: "Incl BTW",                 value: "tend_total",     type: 'amount'     },
      { text: "Excl BTW",                 value: "tend_sub_total",     type: 'amount'     },
      { visible: false, text: "BTW",                      value: "tend_vat_total",      type: 'amount'     },

      { visible: false, text: "Gewijzigd",                value: "updated_at",    type: 'datetime',   },
      { visible: false, text: "Gewijzigd door",           value: "updated_by",                        },
  ]

    var dt = useDataTable("dt_tender_version", "tender_version", api, headers, {
        itemName: {
            prefix: 'de',
            single: 'versie',
            plural: 'versies',
        }, parent: {
            id_tender: null // model.id
        },
        filter: {
            search_type: 'versions'
        },
        noAutoSearch: true, // Search not until the parent id is set.
        canArchive: false
    })

        /**
     *  The status to be used by the chips.
     */
    function chipStatus(item) {
        switch (item.id_status) {
            case Constants.tender.status.STATUS_CONCEPT    : return 'concept';   // 
            case Constants.tender.status.STATUS_ACCEPTED   : return 'accepted';  // 
            case Constants.tender.status.STATUS_REJECTED   : return 'rejected';  // vervallen
            case Constants.tender.status.STATUS_FINISHED   : return 'processed'; // finished 
            case Constants.tender.status.STATUS_OPEN       : return date.isInPast(item.tend_exp_date) ? "expired" : "open";
            default: break;
        }
        return "";
    }

    function statusRep(item) {
        if (item.id_status != Constants.tender.status.STATUS_OPEN) {
            return tenderstatus.oneProp(item.id_status, 'name', '-');
        }
        return date.isInPast(item.tend_exp_date) ? "Verlopen" : "Uitstaand";        
    }

    function onOpen(item) {
        appDlg.open('tender', item);
    }

    class clsPModel {
        id = null;
        inv_number  = null;

        get subTitle() {
            if (this.isLoading || this.isDataLoading) {
                return 'Laden...';
            }
            return `Offerte ${this.tend_number}`;
        }
        async load(id, params) {
            params = params ||{}
            this.tend_number  = params.tend_number;            
            this.id = id;
            dt.setParent({id_tender: id}, true); // true: search
        }
    }

    let model = ref (new clsPModel());

    let dlg = ref(
        new clsDialog("tender_version", "Offerteversies", model, [], {            
            setKeyOnOpen: true,
            icon: 'files',
            maxWidth:'1200px', 
            showNote: false,
            showAttachment: false,
            save:false,
            cancel:'Sluit'
        } )).value;  //TODO

    window.dtl = dt;
</script>
<template>
    <Dialog :dialog="dlg" :showSave="!model.isArchived &&(model.isStatusConcept||model.isNew)">
        <template v-slot:toolbar-left>
            
            <ToolbarField label="Status">
                <StatusChip type="tend" :status="model.chipStatus">{{model.statusRep}}
                <template v-slot:prefix><span v-if="model.isArchived" class="success" title="Offerte is gereed">
                    <Icon small type='check'></Icon>
                </span></template>
              </StatusChip>
          
            </ToolbarField>

            <ToolbarField class="ml-8" label="Relatie">
                <span class="text-primary">{{model.rel_name}}</span>
            </ToolbarField>

            <ToolbarField class="ml-8" label="Totaal excl. BTW">
                <AmountText class="text-primary" v-model='model.totalExcl' :currency='model.currency'/>    
            </ToolbarField>
            <ToolbarField class="ml-8" label="Factuur">
                <span v-if="!model.id_invoice">-</span>
                <ActionButton no-icon link-button v-else action="salesinvoice.open" @click="onOpenInvoice">{{ model.inv_number || 'Concept' }}</ActionButton>
            </ToolbarField>

        </template>
        <template v-slot:toolbar-menu>

            <ActionMenuItem v-if="!model.isNew" icon="copy" action="tender.copy" @click="()=>onCopyTender()">Maak kopie van deze offerte</ActionMenuItem> 
            <ActionMenuItem v-if="!model.isArchived &&(model.isNew||model.isStatusConcept)" :model="model" icon="import" action="tender.modify" @click="()=>onImportTender()">Importeer offerte</ActionMenuItem> 
            <v-divider v-if="!model.isArchived &&(model.isStatusOpen||model.isStatusAccepted)"></v-divider>
            <ActionMenuItem v-if="(model.isStatusOpen||model.isStatusAccepted)" icon="email" action="tender_send.open" @click="()=>onSend()">Stuur offerte nogmaals</ActionMenuItem>
            <ActionMenuItem v-if="(model.isStatusAccepted)" icon="email-check" action="tender_send.open" @click="()=>onSendConfirmation()">Stuur opdrachtbevestiging nogmaals</ActionMenuItem>
            <v-divider v-if="!model.isArchived &&(!model.isNew)"></v-divider>
            <ActionMenuItem v-if="!model.isArchived &&(model.isStatusOpen&&!model.hasUserResponse)" icon="back" action="tender.modify" @click="()=>onConcept()">Terug naar concept</ActionMenuItem>
            <ActionMenuItem v-if="(model.id_tender_original)" icon="files" action="tender.open" @click="()=>onShowVersions()">Toon alle versies</ActionMenuItem>
            <ActionMenuItem v-if="(!model.isNew)" icon="log" action="tender.open" @click="()=>onShowLog()">Toon geschiedenis</ActionMenuItem> 

        </template>        

        <template v-slot:toolbar-right>

            <ActionButton ml action="tender.example" icon="pdf" @click="onExample">Toon PDF</ActionButton>
            <ActionButton v-if="!model.isArchived &&(model.isNew||model.isStatusConcept)" ml action="tender_send.open" icon="email" @click="onSend">Verstuur</ActionButton>
            <ActionButton v-if="!model.isArchived &&(model.isStatusOpen||model.isStatusAccepted)" ml error dropdown icon="remove">
                <span v-if="(model.isStatusAccepted)">Alsnog geen opdracht</span><span v-else>Geen opdracht</span>
                <template v-slot:dropdown>
                    <ActionMenuItem action="tender.modify" icon="remove" @click="()=>onReject()">Offerte vervalt</ActionMenuItem> 
                    <ActionMenuItem action="tender.modify" icon="pdf-plus" @click="()=>onReplaceTender()">Maak nieuwe versie</ActionMenuItem> 
                </template>
            </ActionButton>
            <ActionButton v-if="!model.isArchived &&(model.isStatusOpen && !model.isUserResponseRejected)" ml success action="tender.modify" icon="check" @click="onAccept">Opdracht</ActionButton>

            <ActionButton :model="model" v-if="!model.isArchived &&(!model.isStatusConcept)" ml primary action="tender_finish.open" icon="archive" @click="onFinish">Gereed</ActionButton>

            <ActionButton v-if="!model.isArchived &&((model.isStatusOpen&&!model.isUserResponseRejected)||model.isStatusAccepted)" ml action="tender_createinvoice.open" icon="invoice" @click="onCreateInvoice">Maak factuur</ActionButton>

        </template>


        <template v-slot:tabs-left>
            <v-tabs dense v-model="dlg.tab">        
                <v-tab>Gegevens</v-tab>                
                <v-tab>Brief</v-tab>                
                <v-tab>Regels</v-tab>                
            </v-tabs>
        </template>        

        <v-form ref="formHeader" lazy-validation>
            <v-tabs-items class="relation-tabs mt-2" v-model="dlg.tab">                
                <v-tab-item class="tender-header" transition="none" reverse-transition="none">                        
                        <TenderHeader :model="model"/>
                </v-tab-item>
                <v-tab-item class="tender-letter" transition="none" reverse-transition="none">                        
                        <TenderLetter :model="model"/>
                </v-tab-item>
                <v-tab-item class="tender-lines" transition="none" reverse-transition="none">                        
                    <TenderParagraphs :model="model"/>
                </v-tab-item>
            </v-tabs-items>
        </v-form>

        <DialogTenderImport/>
        <DialogTenderSend/>
    </Dialog>
</template>



<script setup>
    import useModel from '@/models/tender/tender'
    import Dialog from '@shared/ui/dialogs/ActionDialogModal'
    import ActionButton from '@shared/ui/controls/buttons/ActionButton'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import { ref, watch } from 'vue'
    import clsDialog from '@cls/clsDialog'    
    import ToolbarField from '@shared/ui/dialogs/ToolbarField'
    import AmountText from '@controls/text/Amount.vue'

    import eventbus from '@app/eventbus'
    
    import StatusChip from "@controls/widgets/StatusChip.vue";

    import DialogTenderSend from '@/ui/dialogs/tender/DialogTenderSend.vue'    
    import DialogTenderImport from '@/ui/dialogs/tender/DialogTenderImport.vue'    

    import TenderHeader from './TenderHeader'
    import TenderLetter from './TenderLetter'
    import TenderParagraphs from './TenderParagraphs'

    import Constants from '@app/consts'

    import appDlg from '@app/dlg'
    import action from '@app/action'
    import Icon from '@controls/icons/Icon'
    import noty from '@shared/lib/noty'

    const formHeader = ref(null); 
    const formLines = ref(null); 

    let model = useModel();
    let dlg = ref(
        new clsDialog("tender", "Offerte", model, [formHeader, formLines], 
        {
            
            keepOpen: true,
            noteType: "tender_note",
            flagType: "tender_flag",
            attachmentType: "tender_attachment",
            xsm:12,xlg:10,xxl:8
        }
    )).value; 


    /*
     * Save the data or skip when not authorized for.
     * When viewing an example pdf, the tender must first be saved when the user has rights or directly exampled
     * when no modify rights are available
     */  
    async function saveOrSkip() {
        // Do not save an 'open or later' tender
        if (model.isOpenOrLater) {
            return true; 
        }
        if (model.disabled) { // Also, when generally disabled, do not save.
            return true; 
        }
        if (action.can('tender.modify')) {
            if (!await dlg.onSave()) {
                return false;
            }
        }
        return true;
    }

    /**
     * Generate an example
     */
    async function onExample() {        
        if (!await saveOrSkip()) {
            return;
        }
        eventbus.dialog.pdfviewer(async () => model.downloadPdf(), model.downloadTitle);
    }
    /**
     * Generate an example order confirmation
     */
     async function onExampleConfirmation() {        
        if (!await saveOrSkip()) {
            return;
        }
        eventbus.dialog.pdfviewer(async () => model.downloadConfirmationPdf());
    }
    
    async function onSend() {
        if (!await saveOrSkip()) {
            return;
        }
 
        let result = await appDlg.promised.open('tender_send', {id: model.id, email_type:Constants.email.types.ID_EMAIL_TYPE_TENDER});
        dlg.reload();
        eventbus.model.stats.refresh("tender");   
    }

    
    async function onSendConfirmation() {
        if (!await saveOrSkip()) {
            return;
        }
        let result = await appDlg.promised.open('tender_send', {id: model.id, email_type:Constants.email.types.ID_EMAIL_TYPE_TENDER_CONFIRMATION});

        dlg.reload();
        eventbus.model.stats.refresh("tender");   
    }

    /**
     * Reject this tender 
     */
    async function onReject() {
        if (!await saveOrSkip()) {
            return;
        }
        try {
            var result = await appDlg.promised.open('tender_reject', {id: model.id, tend_number: model.tend_number});
            await model.reject();

            dlg.reload();
            eventbus.model.stats.refresh("tender");   
        } catch (e) {
            if (!e.canceled) { throw e;}
        }
    }    
    /**
     * Accept this tender 
     */
    async function onAccept() {
        if (!await saveOrSkip()) {
            return;
        }
        try {

            var result = await appDlg.promised.open('tender_accept', {id: model.id, tend_number: model.tend_number});
            if (result.send_confirmation) {
                await appDlg.promised.open('tender_send', {id: model.id, email_type:Constants.email.types.ID_EMAIL_TYPE_TENDER_CONFIRMATION});
            } else {
                await model.accept();
            }

            dlg.reload();
            eventbus.model.stats.refresh("tender");   
        } catch (e) { if (!e.canceled) { throw e;} }
    }

    // Finish a tender - aka 'meld gereed'
    async function onFinish() {
        if (!await saveOrSkip()) {
            return;
        }
        try {
            await appDlg.promised.open('tender_finish', {id: model.id, tend_number: model.tend_number});
            await model.finish();

            dlg.closeDialog();
            eventbus.model.stats.refresh("tender");   
        } catch (e) { if (!e.canceled) { throw e;} }
    }

    
    
    /**
     * Create an invoice for this tender
     */
     async function onCreateInvoice() {
        if (!await saveOrSkip()) {
            return;
        }
        try {
            var createOptions = await appDlg.promised.open('tender_createinvoice', {id: model.id, id_status:model.id_status, tend_number: model.tend_number});
            var invoice = await model.createInvoice(createOptions);

            if (createOptions.open_invoice) {
                appDlg.open('salesinvoice', {id: invoice.id_invoice})
                dlg.closeDialog();
            } else {
                dlg.reload();
            }
            eventbus.model.stats.refresh("tender");   
        } catch (e) { if (!e.canceled) { throw e;} }
    }

    /**
     * Accept this tender 
     */
    async function onConcept() {        
        if (!await saveOrSkip()) {
            return;
        }
        if (! await noty.tryConfirm('Weet u zeker dat u deze offerte terug wilt zetten naar status Concept?')) {
            return;
        }

        model.toConcept();
    }

    
    /**
     * Select a tender, chapters and actualize flag
     */
    async function onImportTender() {
        if (!await saveOrSkip()) {
            return;
        }
        try {
            let selected = await appDlg.promised.open("tender_import")
            console.log('tender_import result: ', selected)
            model.importChapters(selected.id, selected.chapters, selected.actualize);
        } catch (e) { if (!e.canceled) { throw e;} }
    }

    /**
     * Copy a tender. 
     * @param {*} item 
     */
    async function onCopyTender() {
        if (model.isNew) {
            return;
        }
        if (!await saveOrSkip()) {
            return;
        }
        try {
            var result = await appDlg.promised.open('tender_copy', {id: model.id, tend_number: model.tend_number});
            appDlg.open('tender', result);
            eventbus.model.stats.refresh("tender");   
        } catch (e) { if (!e.canceled) { throw e;} }
    }

    async function onReplaceTender() {
        if (model.isNew) {
            return;
        }
        if (!await saveOrSkip()) {
            return;
        }
        try {
            var result = await appDlg.promised.open('tender_replace', {id: model.id, tend_number: model.tend_number});
            appDlg.open('tender', result);
            eventbus.model.stats.refresh("tender");   
        } catch (e) { if (!e.canceled) { throw e;} }
    }

    async function onShowVersions() {
        appDlg.open('tender_version', model);
    }

    async function onShowLog() {
        appDlg.open('tender_history', model);
    }

    function onOpenInvoice() {
        console.log('model.id_}invoice:', model.id_invoice)
        appDlg.open('salesinvoice', {id: model.id_invoice})
    }

    // 
    window.tenddlg   = dlg;
</script>

import clsApi from '@cls/clsApi'

class clsSalesInvoiceApi extends clsApi {

    credit(id) { 
        return this.get(`credit`, {id: id}); 
    }
    loadStats() {
        return this.get(`stats`); 
    }
    loadSendData(id) { 
        return this.post(`loadsenddata`, {id: id}); 
    }
    loadReminderData(id) { 
        return this.post(`loadsendreminderdata`, {id: id}); 
    }
    emailSend(model) { 
        return this.post(`sendviaemail`, model); 
    }
    async downloadSend(id) {         
        return await this.downloadPostRaw(`sendviadownload`, {id: id});  
    }
    emailReminder(model){ 
        return this.post(`sendreminderviaemail`, model); 
    }
    async downloadReminder(id, type) {         
        return await this.downloadPostRaw(`sendreminderviadownload`, {id: id, type: type});  
    }
    getPdf(id) {
        return this.getRaw(`downloadpdf/{id}`, {id: id}, {hideErrors: true});  
    }
    async createForManday(id_manday) {
        return this.post(`createformanday`, {id: id_manday}); 
    }
    async backToConcept(id) {
        return this.post(`backtoconcept`, {id: id}); 
    }
}

export default clsSalesInvoiceApi;

import eventbus from '@app/eventbus'
import { onUnmounted } from 'vue'
import noty from '@lib/noty'

/**
 * Basic class for generating the backbones for dialogs to select items from lists. 
 * 
 * What is the reason for property setKeyOnOpen?
 * ******************************************************************************************************
 * Vue typically reuses the DOM as efficiently as possible. 
 * That is fine. But in case of dialogs, it means that once the dialog opened, and the components are mounted, 
 * the creation is finished.
 * When the dialog is closed, it is moved to shadow dom and when it is reopened, it is moved to DOM. 
 * This without going through the lifecycle again. 
 * 
 * In most cases this is perfectly fine, except when components used in the dialog must e.g. load new data on mount. 
 * Specificly, this is about datatables. When a dialog contains a datatable, which we typically do, the datatable is 
 * loaded once, and not again when the dialog is reopened for another select action. 
 * 
 * In this case, we force the dialog to go through all lifecycles by generating a unique key for the vue component.
 *     
 */
class clsSelectListDialog {
    setKeyOnOpen= true;
    dlgKey      = undefined;
    modelName   = null
    dt          = null;
    maxWidth    = "850px"
    open        = false
    tab         = 0
    cancel      = "Sluit"
    select      = "Selecteer"
    closeAfterSelect = true    
    
    multiselect = false
    fnMultiSelectCallback = null;
    multiselected = false; // Shortly set to true when any multi selection is made.
    
    cls         = "action-dialog dialog-select"
    icon        = "select"
    title       = "title"
    noSubTitle  = false

    noActions = false
    diabled = false
    fnReject      = null; 
    fnResolve     = null;
    
    // Projects and possible other select lists support subselects. 
    // In this case, whenever a project is selected and the project has chapters, a chapter can be selected.
    // However, this is not desired in all cases. When no subselect should be used, set the noSubItemSelect option. 
    noSubItemSelect = false;

    // Default focusfield. 
    // Usage:
    //      <control ref="q"></control>
    // ...
    //    const q = ref()
    // ...
    //   new clsSelectListDialog(....., {defaultFocus: ()=>q.value}) 
    defaultFocus = null 

    xl = 12;    // The columns dedicated to show the body in - xtra large device 
    lg = 12;    // The columns dedicated to show the body in - large device 
    sm = 12;    // The columns dedicated to show the body in - small device 

    setUniqueDialogKey() {
        if (!window._sldlg) {
            window._sldlg = 1;
        }
        window._sldlg++;
        this.dlgKey = window._sldlg + 1;;
    }

    async closeDialog(force) {
        if (this.fnReject) {
            this.fnReject(null);
        }
        this.open = false;
    }
    openDialog() {
        if (this.setKeyOnOpen) {
            this.setUniqueDialogKey();
        }
        this.open = true;
        // Remove the context for a short period as the reference to the field is probably not yet resolved.
        setTimeout( ()=> this.setDefaultFocus(), 100)
        // this.setDefaultFocus();
    }
    setDefaultFocus() {
        if (!this.defaultFocus || !(this.defaultFocus instanceof Function)) {
            return;
        }
        let focusCtrl = this.defaultFocus();
        if (focusCtrl && focusCtrl.$el && focusCtrl.$el.getElementsByTagName) {
            var $el = focusCtrl.$el.getElementsByTagName('input');
            if ($el && $el.length && $el[0].focus) {
                $el[0].focus();
                $el[0].select();
            }
        }
    }

    get disabled() {
        return false;
    }

    // Overrides for functions like 'onBulkRemove', etc
    callbacks= {

    }

    getSelectableLines(evtTarget) {
        if (!evtTarget) {
            return null;
        }
        var dlg = evtTarget.closest('.v-dialog');
        if (!dlg) {
            return;
        }
        var selectables = dlg.getElementsByClassName('fa-square-plus')
        if (!selectables || !selectables.length) {
            return null;
        }
        // convert html collection to js array
        selectables = [...selectables];
        return selectables;
    }
    getSelectedIndex(evtTarget) {
        var lines = this.getSelectableLines(evtTarget);
        if (!lines) {
            return -1;
        }
        var selectedIndex = lines.findIndex( (line) => line && line.classList && line.classList.contains('select-list-selected'));
        return selectedIndex;
    }
    selectNext(evtTarget) {
        var lines = this.getSelectableLines(evtTarget);
        if (!lines || !lines.length) {
            return;
        }
        var ix = this.getSelectedIndex(evtTarget);
        var ixNext = ix+1;
        if (ix >= (lines.length -1)) {
            ixNext = 0;
        }
        this.selectByIndex(evtTarget, ixNext);
    }
    selectPrev(evtTarget) {
        var lines = this.getSelectableLines(evtTarget);
        if (!lines || !lines.length) {
            return;
        }        
        var ix = this.getSelectedIndex(evtTarget);
        var ixNext = ix-1;
        // When the focus goes beyond the first line, we want to focus on the first search field again.
        if (ix == 0) {
            var dlg = evtTarget.closest('.v-dialog'); // It exists, otherwise we had not found any lines.
            var forms = dlg.getElementsByTagName('form');
            if (forms && forms.length) {
                var form = forms[0];
                var firstInput = form.getElementsByTagName('input');
                if (firstInput && firstInput.length) {
                    setTimeout( () => {
                        firstInput[0].select();
                        firstInput[0].focus();
                    }, 10);
                }
            }
            // Make sure the previous line was de-selected.
            lines[ix].classList.remove('select-list-selected')
            return; // And we are done.

        } else if (ix < 0) {
            // Otherwise, when currently no line has the focus, we are done. Do not set the focus via up key. 
            return;
        }
        this.selectByIndex(evtTarget, ixNext);
    }
    selectByIndex(evtTarget, ix) {
        window.evtTarget = evtTarget;
        
        var lines = this.getSelectableLines(evtTarget);
        if (!lines || !lines.length) {
            return;
        }
        // When we are here, we don't want the input field to have focus as pressing enter would trigger a search.
        // Therefore, we set the focus to the dialog. The user can access the textfield easy by pressing tab.
        var dlg = evtTarget.closest('.v-dialog'); // It exists, otherwise we had not found any lines.
        dlg.click(); 
        dlg.focus(); 

        var ixNow = this.getSelectedIndex(evtTarget);        
        if (ixNow >=0) {
            lines[ixNow].classList.remove('select-list-selected')
        }
        if (ix < 0 || ix >= lines.length) {
            return; // can not select
        }
        lines[ix].classList.add('select-list-selected')
        lines[ix].scrollIntoView({block: "start", behavior: "smooth"});
        lines[ix].closest('table').scrollTop -= 10;
    }
    selectSelected(evtTarget) {
        var dlg = evtTarget.closest('.v-dialog'); // It exists, otherwise we had not found any lines.
        if (!dlg) {
            return;
        }
        var selected = dlg.getElementsByClassName('select-list-selected')
        if (!selected || !selected.length) {
            return;
        }
        selected[0].click()        
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // 
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    onKeyDown(evt, b, c) {
        if (evt && evt.key == "Escape") {
            this.onClose()
        }
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        //
        // Yes. We handle the javascript events with plane javascript. 
        // On key up and down, scroll through the actual list. 
        //
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        if (evt && evt.key == "ArrowDown") {
            this.selectNext(evt.target);
        }
        else if (evt && evt.key == "ArrowUp") {
            this.selectPrev(evt.target);            
        }
        else if (evt && evt.key == "Enter") {
            this.selectSelected(evt.target);            
        }
        // let keyCode = $event.keyCode ? $event.keyCode : $event.which;
        // 38: key up, 40: key down
        // if (($event && $event.target && undefined != $event.target.selectionStart) && (keyCode == 38 || keyCode == 40)) {

        if (this.callbacks.onKeyDown) {
            this.callbacks.onKeyDown(evt)
        }
    }

    onClose() {
        if (this.callbacks.onClose) {
            this.callbacks.onClose()
        }
        else {
            this.closeDialog();
        }
    }
    async onBeforeResolve(items) {
        return items;
    }

    indicateMultiSelected() {
        this.multiselected = true;  

        setTimeout(()=>this.multiselected = false, 1000);
    }

    async onSelected(items, forceClose) {
        if (!items || !items.length) {
            noty.alert("U heeft geen regels geselecteerd.");
            return;
        }
        // When a multiselect callback is specified, each line is handled by the callback and 
        // the dialog is still open.
        if (this.fnMultiSelectCallback) {
            items = await this.onBeforeResolve(items);
            this.fnMultiSelectCallback(items);
            this.indicateMultiSelected();
            if (forceClose) {
                this.closeDialog();
            }
            return;
        }

        if (this.fnResolve) {
            items = await this.onBeforeResolve(items);
            this.fnResolve(items);
        }
    }
    onSelect() {
        if (this.callbacks.onSelect) {
            this.callbacks.onSelect()
        }
        else {
            this.onSelected(this.dt.selectedItems());
        }
    }

    
    onOpen(params) {
        if (this.callbacks.onOpen) {
            this.callbacks.onOpen(params)
        }

        this.openDialog();
        this.tab = 0;
    }

    evtRegistrations = []
    registerEvents() {
        var self = this;
        this.evtRegistrations.push(
            // fnReject, fnResolve, modelName
            eventbus.dialog.open.onPromised( (fnResolve, fnReject, modelName, params) => {    
                if (modelName != self.modelName) {
                    return;
                }
                self.onOpen(params);
                this.fnReject      = fnReject;
                this.fnResolve     = fnResolve;
                this.noSubItemSelect = params && (params.noSubItemSelect);
                
//            eventbus.dialog.open.on((modelName, params) => {
                if (params && (undefined !==params.multiselect)) {
                    this.multiselect = params.multiselect;
                }
                this.fnMultiSelectCallback = params.fnMultiSelectCallback || null;
                this.closeAfterSelect = (this.fnMultiSelectCallback ? false : true);

                this.keepOpen = !!this.fnMultiSelectCallback;
                if (this.multiselect) {
                    this.dt.setModeMultiSelect();
                } else {
                    this.dt.setModeSingleSelect( (item) => {
                        self.onSelected([item]);
                    });
                }
                // Clear the existing filter as it may contain values for the previous search action
                for (var key in this.dt.filter) {
                    this.dt.filter[key] = null;
                }
                // Exclude specific ids.                                
                this.dt.filter.exclude = params && params.exclude || [];
                // Set the provided filter values. 
                // Note that you can not just set random values. In the datatable constructor, the filterable fields need to be defined. 
                // For an example, see DialogSelectProject.vue
                if (params && params.filter) {
                    for (var key in params.filter) {
                        this.dt.filter[key] = params.filter[key];
                    }                                    
                }

                return "this.dlgKey";
            })
        )
    }
    cleanUp() {
        (this.evtRegistrations||[]).forEach( (fnUnRegister) => fnUnRegister() ) 
        this.dt.actionDblClick = null;
        this.dt.actionClick = null;
    }

    /**
     * Let double click in the datatable serve as single select.
     */
    setupDblClickHandler() {
        var self = this;
        this.dt.actionDblClick = function(evt, item) {
            self.onSelected([item], true /* force close */);        
        }
    }

    /**
     * Let single click in the datatable select the line.
     * Note that 'select the line' just sets a class in the html element which visually selects the line 
     * and which allows us to select the line later on via e.g. an enter key handler.
     */
    setupClickHandler() {
        var self = this;
        this.dt.actionClick = function(item, ix, evt) {
            self.selectByIndex(evt.target, ix);
        }
    }


    constructor(modelName, title, dt, options) {
        options = options||{}
        this.modelName = modelName;
        this.title = title;
        var self = this;
        this.dt = dt;
        this.registerEvents();
        this.setUniqueDialogKey();
        for (var key in options) {
            this[key] = options[key];
        }
        this.setupDblClickHandler();
        this.setupClickHandler();
        onUnmounted(() => {
            self.cleanUp();
        })
    }
}

export default clsSelectListDialog;
import clsApiPost from '@cls/clsApiPost'

class clsProject extends clsApiPost {

    loadStats() {
        return this.get(`stats`); 
    }

    async changeProjectPhase(ids, id_project_phase) {
        return this.post('changeprojectphase', {ids: ids, id_project_phase: id_project_phase})
    }

    async merge(model) {
        return this.post('merge', model)
    }

}

export default clsProject;

<template>
    <Dialog :dialog="dlg" @keydown="(evt) => onKeyDown(evt)">
        <template v-slot:toolbar-left>
            
            <ToolbarField label="Status">
                <StatusChip type="salesinvoice" :substatus="model.subStatus" :status="model.inv_status">{{model.statusRep}}</StatusChip>

            </ToolbarField>

            <ToolbarField class="ml-8" label="Relatie">
                <span class="text-primary">{{model.rel_name}}</span>
            </ToolbarField>

        </template>
        <template v-slot:toolbar-right>

            <ActionButton ml action="salesinvoice.example" :disabled="model.isDataLoading||model.isLoading" icon="pdf" @click='()=>onExample()'>Voorbeeld</ActionButton>            
            <ActionButton ml action="salesinvoice_send.open" :disabled="model.isDataLoading||model.isLoading" icon="send" @click='()=>onSend()'>Verstuur</ActionButton>            

        </template>

        <template v-if="!model.isNew" v-slot:toolbar-menu>

            <ActionMenuItem v-if="model.isOpenOrLater" action="salesinvoice_reminder.open" @click="()=>onReminder()">Stuur een herinnering</ActionMenuItem> 
            <ActionMenuItem action="relation.open" @click="()=>appDlg.open('relation', {id: model.id_relation})"></ActionMenuItem> 
            <ActionMenuItem v-if="model.isOpenOrLater" action="salesinvoice.backtoconcept" @click="()=>onBackToConcept()"></ActionMenuItem> 
            <v-divider v-if="model.isOpenOrLater"></v-divider>
            <ActionMenuItem v-if="model.isOpenOrLater" action="salesinvoice.copy" @click="()=>onCopy()">Maak kopie van deze factuur</ActionMenuItem> 
            <ActionMenuItem v-if="model.isOpenOrLater&&!model.isCreditInvoice" action="salesinvoice.credit" @click="()=>onCredit()">Maak credit factuur</ActionMenuItem> 
            <v-divider v-if="model.isOpenOrLater"></v-divider>
            <ActionMenuItem v-if="model.isOpenOrLater" action="salesinvoice_payments.open" @click="()=>onOpenPayments()"></ActionMenuItem> 
            <ActionMenuItem v-if="model.isOpenOrLater" action="salesinvoice_history.open" @click="()=>appDlg.open('salesinvoice_history', {id:model.id, inv_status: model.inv_status, inv_number: model.inv_number})"></ActionMenuItem> 

        </template>        

        <template v-slot:tabs-left>
            <v-tabs dense v-model="dlg.tab">        
                <v-tab>Factuur</v-tab>                
            </v-tabs>
        </template>        

        <v-tabs-items class="sales-invoice-tabs mt-0" v-model="dlg.tab">                
            <v-tab-item class="sales-invoice-main-editor" transition="fade-transition" reverse-transition="fade-transition">                        
                <v-form ref="formHeader" lazy-validation>
                    <SalesInvoiceHeader class="" :model="model"/>
                    <SalesInvoiceLines class="sales-invoice-lines my-4" :model="model" @addline="addLine" @addemptytextline="addEmptyTextLine"/>
                    <SalesInvoiceFooter class="my-4" :model="model"/>                    
                </v-form>
            </v-tab-item>
        </v-tabs-items>
        
        <DialogSelectManday/>        
    </Dialog>
</template>



<script setup>
    import useModel from '@/models/salesinvoice/salesinvoice'
    import Form from '@controls/forms/Form'
    import FormColumn from '@controls/forms/FormColumn'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/ActionDialogModal'
    import ActionButton from '@shared/ui/controls/buttons/ActionButton'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import { ref, watch, onMounted, onUnmounted } from 'vue'
    import clsDialog from '@cls/clsDialog'
    import noty from '@lib/noty'
    import ToolbarField from '@shared/ui/dialogs/ToolbarField'
    import Amount from "@controls/text/Amount.vue";
    import DatePicker from '@controls/input/DatePicker'
    import Checkbox from "@controls/check/Checkbox.vue";
    
    import Icon from '@controls/icons/Icon'

    import eventbus from '@app/eventbus'
    import ToggleButtonGroup from '@controls/buttons/ToggleButtonGroup'
    
    import StatusChip from "@controls/widgets/StatusChip.vue";
      
    import DialogSelectManday from '@/ui/dialogs/select/DialogSelectManday'

    import SalesInvoiceHeader from './SalesInvoiceHeader'
    import SalesInvoiceLines from './SalesInvoiceLines'
    import SalesInvoiceFooter from './SalesInvoiceFooter'

    import appDlg from '@app/dlg'
    import action from '@app/action'
    
    const formHeader = ref(null); 
    const formLines = ref(null); 

    let model = useModel();
    let dlg = ref(
        new clsDialog("salesinvoice", "Verkoopfactuur", model, [formHeader, formLines], 
        {
            
            keepOpen: true,
            noteType: "salesinvoice_note",
            flagType: "salesinvoice_flag",
            attachmentType: "salesinvoice_attachment",
            nosm:8,nolg:8,noxl:8
        }
    )).value; 

    // Save the data or skip when not authorized for. 
    async function saveOrSkip() {
        if (action.can('salesinvoice.modify')) {
            if (!await dlg.onSave()) {
                return false;
            }
        }
        return true;
    }
    async function onExample() {
        if (!await saveOrSkip()) {
            return;
        }
        eventbus.dialog.pdfviewer(async () => model.downloadPdf(), model.downloadTitle);
    }
    async function onSend() {
        if (!await saveOrSkip()) {
            return;
        }
        let result = await appDlg.promised.open('salesinvoice_send', {id: model.id});
        // Instead of reloading the data in the model via model.reload(), we let the dialog reload. 
        // In this case, the dialog can close on failure, which means it will not be open in a failed state.
        dlg.reload();
//        model.reload();
    }
    async function onReminder() {
        if (!await saveOrSkip()) {
            return;
        }
        result = await appDlg.promised.open('salesinvoice_reminder', {id: model.id});
        dlg.reload();
//        model.reload();
        
    }    
    async function onBackToConcept() {
        if (await noty.confirm("Wilt u de factuur terugzetten naar status concept?")) {
            model.backToConcept();
        }
    }
    async function onCopy() {
        try {
            await noty.confirm("Weet u zeker dat u deze factuur wilt kopieren?") 
        } catch (e) { return; } // canceled.
        if (!await saveOrSkip()) {
            return;
        }
        model.createCopy();
    }
    async function onCredit() {
        try {
            await noty.confirm("Weet u zeker dat u een creditfactuur wilt aanmaken?") 
        } catch (e) { return; } // canceled.
        if (!await saveOrSkip()) {
            return;
        }
        model.createCredit();
    }

    /**
     * The function title says it. Wee need to: 
     * - when a line is added, focus either the first input field, or the first ProseMirror field, depending on which one is
     *   found first in a column. 
     * 
     * This is not 'vue-ish'. However, doing this with refs in the child components makes it a lot more difficult to implement.
     * Therefore, select the target nodes ourselves.
     * 
     * Parameter evt is provided by click events and we get it from the clicked entities. 
     * Especially for the click event we want to make sure it was in the dialog. 
     * 
     * Note that when the event is not provided, we find the dialog ourselves. 
     * E.g. when triggered from a menu, the event source is not in the dialog as the menu is rendered in the root of the document.
     * 
     * So, the evt parameter can be omitted when we are sure it is triggered from our component.  
     * 
     */
    function setFocusToFirstInputInLastLine(evt) {
        var dlg = (!evt) ? document.getElementsByClassName('dialog-salesinvoice')?.[0] : evt.target.closest('.dialog-salesinvoice')
        var lines = dlg?.getElementsByClassName('sales-invoice-lines')
                        ?.[0].getElementsByTagName('tbody')
                        ?.[0].getElementsByTagName('tr');        
        if (!lines || !lines.length) {
            return;
        }
        // Ok. Well. Now we need to select either, the first input control, or the first ProseMirror control... 
        // That means, we need to loop through all td's. Otherwise we don't know which one comes first.
        var lastLine = lines[lines.length-1];
        var tds = lastLine.getElementsByTagName('td');
        for (var n = 0; n < tds.length; n++) {
            var td = tds[n];
            var inp = td.getElementsByTagName('input')?.[0] || td.getElementsByClassName('ProseMirror')?.[0];
            if (inp) {
                inp.focus();
                return;
            }
        }
    }
    function addLine(evt) {
        model.addLine();
        setTimeout( ()=>setFocusToFirstInputInLastLine(evt), 0)
    } 
    function addEmptyTextLine(evt) {
        model.addTextLine();
        setTimeout( ()=>setFocusToFirstInputInLastLine(null /* When triggered from a menu, the event target is rendered outside the dialog */), 0)
    } 
    function onKeyDown(evt) {
        if (evt && evt.key == "l" && evt.ctrlKey) {
            evt.stopPropagation();
            evt.preventDefault();
            addLine(evt);
        }
    }    

    async function onOpenPayments(item) {
        try {
            var result = await appDlg.promised.open('salesinvoice_payments', {id:model.id});
            if (result.inv_status) {
                model.inv_status = result.inv_status;
            }
        } catch (e) {} // canceled.
    }



    // 
    window.sidlg   = dlg;
</script>

//
// The eventbus is used to provide an easy way for executing pub/sub chaining. 
// 
// The eventbus has some predefined events for cases where the events need to be used system wide. 
// Events must be defined in eventbus.registrations.js
// 
// 
// Import: 
//  import clsEventbus from '@shared/lib/core/clsEventbus';
//
// 
//  Usage Examples
//  -----------------------------------------------
//  1) send a message to get an alert dialog started.
//     eventbus.dialog.alert({msg: 'Watch out!'});
//     
//  2) Repond to the alert message by displaying a log in the console
//     eventbus.dialog.alert.on( (para) => { console.log(para.msg); });     
//
//  Usage in vue components
//  -----------------------------------------------
//  Typically, register the event in the mounted stage. 
//  Unregister the event in the onBeforeDestroy to prevent duplicate event handlers.
//  E.g. : 
// 
//      mounted() {
//          var on_edit = function(para) { alert('editing ' + id); };
//          eventbus.dialog.relation.edit.on(this.on_edit);
//     },
//     
//     beforeDestroy() {
//          eventbus.dialog.relation.edit.on(this.on_edit);
//     },
// 
//  Usage with promises
//  -----------------------------------------------
//  Use the .promise extension to broadcast an event and retrieve a promise. 
//  Note that a rejected promise is returned when no entity is listening for the broadcasted event
//  via the onPromised binding.
// 
//  Example: 
//      eventbus.dialog.confirm.promise({title: 'Really?', body: 'Are you sure?'})    
//                             .then(  () => {alert( 'Yep )})
//                             .catch( () => {alert( 'Nope)})
// 
//  and on the listening side: 
//      eventbus.dialog.confirm.onPromised( (para) {
//          if (confirm(para.body)) {
//              para.resolve();
//          } else { 
//              para.reject();
//          }
//      })    
// 
// 

import Vue from 'vue'

class clsEventbus {

    eventbus = new Vue();

    /**
     * Constuctor. In most cases you should override only registerEvents.
     */
    constructor() {
        this.registerDefaultEvents();
        this.registerEvents();
    }

    // Dump the number of subscriptions.
    dump(verbose) {
        var arr = [];
        var cnt = 0;
        for (var key in this.eventbus._events) {
            var n =this.eventbus._events[key].length; 
            cnt += n
            if (verbose) {
                console.log(`${n}     `.substring(0, 5) + key)
            }
        }
        console.log(`Total subscriptions: ${cnt}`)
    }

    /**
     * Simple logger for eventbus activity
     * 
     * 1) To show all sent events in the log: 
     *      eventbus.debug()
     *    or:
     *      eventbus.debug(true)
     * 
     * 2) To turn off eventbus logs:  
     *      eventbus.debug(false)
     * 
     * 3) To show only particular events :  
     *      eventbus.debug('invoice') -- only events which contain the phrase 'invoice'
     * 
     */
    dbgFlag = null;
    debug(value) {
        if (undefined === value) {
            value = true;
        }
        if (value === true) {
            this.dbgFlag = true;
        } else if (value === false) {
            this.dbgFlag = false;
        } else {
            this.dbgFlag = value;
        }
    }

    /**
     * Log is called when debug is set.
     * @param {*} event 
     * @param {*} para 
     * @returns 
     */
    log(event, para, para2, para3, para4, para5) {
        if (!this.dbgFlag) {
            return;
        }
        if (this.dbgFlag !== true) {
            if ( (""+event).indexOf(this.dbgFlag) < 0 ) {
                return;
            }
        }
        console.log(`EVENTBUS [${event}]`, para, para2, para3, para4, para5);
    }

    /**
     * log a promised Event.
     * @param {} event 
     * @param {*} para 
     * @returns 
     */
    logPromise(event, para1, para2, para3) {
        if (!this.dbgFlag) {
            return;
        }
        if (this.dbgFlag !== true) {
            if ( (""+event).indexOf(this.dbgFlag) < 0 ) {
                return;
            }
        }
        console.log(`PROMISED EVENTBUS [${event}]`, para1, para2, para3);
    }

    /**
     * 
     * Register an event plus the on/off handlers to the eventbus. 
     *  
     * Example: 
     * eventbus.register('orders', 'requestSave');
     * 
     */
    register = function() {
        
        if (arguments.length <2) {
            console.error("eventbus registration must have at least 2 components", arguments);
            return;
        }
        
        let eventbus = this.eventbus;
        var self = this;

        var root = eventbus;
        var event = "event";
        for (var n = 0; n < arguments.length-1; n++) {
            var component = arguments[n];
            if (!root[component]) {
                root[component] = {};
            }
                
            event = event + "." + component;
            root = root[component];
        }
        // eventbus[a][b][c] = function()
        var final = arguments[arguments.length-1];
        var event = event + "." + final;        
        if (!root[final]) {

            root[final] = function(para, para2, para3, para4, para5) {
                para = para || {};
                self.log(event, para, para2, para3, para4, para5);
                eventbus.$emit(event, para, para2, para3, para4, para5);
            }
            // When a subscriber is registered via the onWithPromise method, we can return a promise
            root[final].promise = function(para1, para2, para3) {
                para1 = para1 || {}
                para2 = para2 || {}
                para3 = para3 || {}
                // When there are no subscribers, the promise will never be fullfilled.
                if (!root[final].promiseSubscriberCount) {
                    console.error("Waiting on promised event without subscribers. Promise rejected, no event sent.", event);
                    return Promise.reject("Rejected - no subscribers");
                }
                self.logPromise(event, para1, para2, para3);
                return new Promise(function(resolve, reject) {
                    eventbus.$emit(event, resolve, reject, para1, para2, para3);
                });
            }
            // Register an event and return a function which can unregister it. Of course, unregistering can also be done 'manually'
            root[final].on = function(fn) {
                eventbus.$on(event, fn); return function() { eventbus.$off(event, fn); }
            }
            root[final].off = function(fn) {
                eventbus.$off(event, fn); 
            }
            root[final].onPromised = function(fn) {
                if (!root[final].promiseSubscriberCount) {
                    root[final].promiseSubscriberCount = 0;
                }
                root[final].promiseSubscriberCount++;
                eventbus.$on(event, fn); 
                return function() { 
                    if (root[final].promiseSubscriberCount) {
                        root[final].promiseSubscriberCount--;
                    }
                    eventbus.$off(event, fn); 
                };
            }
            root[final].offPromised = function(fn) {
                if (root[final].promiseSubscriberCount) {
                    root[final].promiseSubscriberCount--;
                }
                eventbus.$off(event, fn); 
            }
        }
    }

    /**
     * Default events. We define a set already which are required in most applications. 
     * If not, override this method and just do nothing.
     * 
     * To register events in your own application, override the registerEvents method.
     * 
     */
    registerDefaultEvents() {
        /////////////////////////////////////////////////////////////////////////////////
        // Errors
        /////////////////////////////////////////////////////////////////////////////////
        this.register('api', 'error')   

        /////////////////////////////////////////////////////////////////////////////////
        // System events
        /////////////////////////////////////////////////////////////////////////////////
        this.register('accountmenu', 'toggle') // toggle the accountmenu
        this.register('route', 'go')          // select another route
        this.register('route', 'get')         // get the current route
        this.register('route', 'resolve')     // resolve the route by url
        this.register('display', 'mode')      // display: 'mobile', 'desktop'
        this.register('help', 'open')         // show help
        this.register('export',  'excel');    // export to excel
        this.register('datatable',  'configure');    // configure columns
        this.register('datatable',  'browse', 'previous');    //  browse previous
        this.register('datatable',  'browse', 'next');    //  browse next
        this.register('datatable',  'browse', 'info');    //  get browse info

        this.register('model',  'saved');     // generic model saved event. Usage: eventbus.model.saved('modelname', data)
        this.register('model',  'removed');   // generic model removed event. Usage: eventbus.model.removed('modelname', data)
        this.register('model',  'archived');  // generic model archived event. Usage: eventbus.model.archived('modelname', data)
        this.register('model',  'unarchived');  // generic model unarchived event. Usage: eventbus.model.unarchived('modelname', data)
        this.register('model',  'stats', 'refresh');  // generic event which is used to signal a statistics change event. Usage: eventbus.model.stats.refresh('purchaseinvoice')

        this.register('appdata',  'loaded');     // generic event for handling loaded appdata. Usage: eventbus.appdata.loaded(data)


        this.register('dialog', 'open' );     // generic dialog open event. Usage: eventbus.dialog.open('modelname', data)
        this.register('dialog', 'create');    // generic dialog create event. Usage: eventbus.dialog.create('modelname', data) -- optional data
        this.register('dialog', 'copy');      // generic dialog clone event. Usage: eventbus.dialog.clone('modelname', id) -- optional data
        this.register('dialog', 'close');     // generic dialog close event. Usage: eventbus.dialog.close('modelname') -- when modelname is ommitted, all dialogs are closed.

        /////////////////////////////////////////////////////////////////////////////////
        // Authorisation
        /////////////////////////////////////////////////////////////////////////////////
        this.register('auth', 'loggedOut');
        this.register('auth', 'loggedIn');
        this.register('auth', 'loaded');     // loaded the data for the current session.
        this.register('auth', 'navigation', 'loaded');     // Navigational parameters (e.g. menu's) have been set afterloading authorization.
        this.register('dialog', 'auth', 'changepassword')
        this.register('dialog', 'auth', 'changeusername')
        this.register('dialog', 'auth', 'setup2fa')

        /////////////////////////////////////////////////////////////////////////////////
        // General dialogs and notifications
        /////////////////////////////////////////////////////////////////////////////////
        this.register('dialog', 'alert')
        this.register('dialog', 'confirm')
        this.register('dialog', 'prompt')
        this.register('dialog', 'promptpassword')
        this.register('dialog', 'confirm', 'password')
        this.register('snackbar', 'info')
        this.register('snackbar', 'error')
        this.register('dialog', 'datatable', 'configure')
        this.register('dialog', 'pdfviewer');
        this.register('dialog', 'googlemap');

        /////////////////////////////////////////////////////////////////////////////////
        // document downloads
        /////////////////////////////////////////////////////////////////////////////////
        this.register('document', 'download');

        /////////////////////////////////////////////////////////////////////////////////
        // Currencies
        /////////////////////////////////////////////////////////////////////////////////
        this.register('currency', 'saved');

        /////////////////////////////////////////////////////////////////////////////////
        // Attachments
        /////////////////////////////////////////////////////////////////////////////////
        this.register('attachments', 'saved');
        /////////////////////////////////////////////////////////////////////////////////
        // Notes
        /////////////////////////////////////////////////////////////////////////////////
        this.register('notes', 'saved');
    }


    /**
     * Overwrite this method to implement your own events.
     */
    registerEvents() {

    }

    
}

export default clsEventbus;

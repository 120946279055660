import eventbus from '@app/eventbus'
import {datatables as dtsettings} from '@app/settings';
import string from '@lib/string'
import {datetime, date, time} from '@lib/date'
import XLSX from 'xlsx'

/**
 * local table. 
 * Local table is used to wrap local data in a data-table like representation. 
 * 
 * The main reason for this specific implementation (as opposed to using the default datatable functionallity) is that 
 * we want to be able to use this in e.g. invoice lines, where we also want to be able to drag/drop et all.
 * 
 * 
 * Note: 
 *    - the local table supports 'checkboxed' actions. 
 *      For this to work, the data lines must have a 'checked' property from the beginning to be reactive
 *    - Expandable rows can be accomplished but it requires some work. 
 *      Instead of summing it all up here, see the code in models/paymantorder.js and DialogPaymentOrder.vue
 * 
 * Note also: 
 *    - in the constructor for the local table, use the parent model and the lines property for accessing the lines. 
 *      So, for examle: 
 *          let tbl = new clsLocalTable(..., ..., { model: employeeModel, modelLinesProp: 'tariffs'})
 * 
 *      I reckon it would be more transparant if you could just write: 
 *          let tbl = new clsLocalTable(..., ..., { items: employeeModel.tariffs})
 *      
 *      However, the reactive system does not pickup all changes in the array this way.
 *      This could be resolved by providing a computed getter for the lines. 
 *      However this would make it harder to implement and, more important, resultis in cyclic object value errors in some cases. 
 *   
 * Note this as well: 
 *      As for now, sorting is not applicable for local tables. 
 *      For example, the invoice lines, there is no use in sorting them.
 *      When required, it should be implemented in the 
 * 
 * Usage:
 * 
 *  <LocalTable :dt="tblLocal" >
 *      <template v-slot:menu="{ item }">
 *          <ActionMenuItem action="relation.open" :payload="item">Open leverancier</ActionMenuItem>
 *          <v-divider></v-divider>
 *          <ActionMenuItem icon="product.modify" @click="model.removeVendor(item)">Verwijder leverancier</ActionMenuItem>
 *      </template>
 *      <template v-slot:item.purchase_price_excl_vat="{ item }">
 *          <NumberField amount :skeleton="model.isDataLoading" :disabled="model.disabled" :rules="model.rules.purchase_price_excl_vat"  v-model="item.purchase_price_excl_vat"></NumberField>
 *      </template>
 *  </LocalTable>
 *  
 * import LocalTable from '@datatable/LocalTable'  
 * import clsLocalTable from '@lib/clsLocalTable'
 * import useModel from '@/models/employee'
 *
 * let headers = [ 
 *    { "type": "cmd" }, 
 *    { "text": "Leverancier", "value": "rel_name" }, 
 *    { "text": "InkoopPrijs", "value": "purchase_price_excl_vat" }, 
 *    { "text": "Status", "value": "archived_at", fmt: (v,i) => !v ? 'Actief' : 'Archief' }, 
 * ];
 * 
 * let model = useModel();
 * ....
 * .... 
 * let tblLocal = new clsLocalTable("productsupliers", headers, {
 *     noDataText: "Er zijn geen leveranciers gekoppeld",
 *     model: model, 
 *     modelLinesProp: model.tariffs    
 * });
 * 
 */
class clsLocalTable {

    name = null
    height = null; 
    hideHeader = false
    noDataText = "Geen gegevens beschikbaar"
    callbacks = {}
    allHeaders = [];
    defaultHeaders = []; // The default header configuration
    headers = [];
    checkboxed = false;
    stateCheckAll=false
    useCommandPane = true;
    dense = true;
    showFooter = false;
    minimalFooterRows = 2; // Show the footer only by 2 or more rows.
    model = null;
    modelLinesProp = "lines";

    get items() {        
        if (!this.model) {
            return [];
        }
        if (!this.modelLinesProp) {
            return [];
        }
        return this.model[this.modelLinesProp];        
    }

    // The setter is required for supporting draggable lines.
    set items(value) {        
        if (!this.model) {
            throw "localTable: no model available";
        }
        if (!this.modelLinesProp) {
            throw "localTable: no model lines prop defined";
        }
        this.model[this.modelLinesProp] = value;        
    }

    /**
     * Get the configuration of the headers from the user settings.
     * The config looks like: 
     * 
     *   { 
     *       "noteCount"  : 1,
     *       "pi_status"  : 1,
     *       "type"       : 0,
     *       "is_paid"    : 1,
     *       "created_at" : 0,
     *       "rel_name"   : 1,
     *   }
     * 
     */
    applyColumnConfigToHeaders() {
        // Get the column configuration. When not saved yet, we are done here.
        var arrConfig = dtsettings.getColumns(this.name);
                if (!arrConfig ||!arrConfig.length) {
            return;
        }          
        // Assign order index to the current headers. 
        // This will be the base for sorting later on when the config is applied. 
        // By setting the sorting on the full header set, we are sure that when - in code - a header is added later on, 
        // that it is also rendered, and also on a logical place, allthough it is not in the config.  
        for (var n =0; n < (this.allHeaders||[]).length; n++) {
            this.allHeaders[n].ixOrder = n; 
        }

        // Convert the configuration to an associative array for easier searching and add an order index as well.
        var config = {};
        for(var n = 0; n < arrConfig.length; n++) {
            var line = arrConfig[n];
            if (line.value) {
                config[line.value] = {value: line.value, ixOrder:n, visible: line.visible}; 
            }
        }

        // Now, apply the visible and new index settings from the config to the headers.        
        for (var n =0; n < (this.allHeaders||[]).length; n++) {
            var value = this.allHeaders[n].value;
            if (config[value]) {
                // If the column can not move, keep the index.
                if (this.allHeaders[n].canmove !== false) {
                    this.allHeaders[n].ixOrder = config[value].ixOrder;
                }
                // If the column can be hidden, apply the config. Otherwise, just visible
                this.allHeaders[n].visible = (this.allHeaders[n].canhide === false) || config[value].visible;
            }
        }
        // And now, inplace sort by the index.
        this.allHeaders.sort( (a,b) => a.ixOrder - b.ixOrder);
    }
    
    /**
     * Get the datatable settings from settings. This is a placeholder for later implementation.
     */
    getDataTableSettings() {
        return null;
    }
    
    /**
     * Set the headers according to the properties in the  
     *  
     */
    setHeaders() {
        this.applyColumnConfigToHeaders();

        this.headers = (this.allHeaders ||[]).filter( (h) => !h.excelOnly && (h.type == "cmd" || h.type=="drag" || h.visible!==false || h.canhide === false));            
        this.headers.forEach( (header) => {
            // By default sortable. BTW, it is not implemented in a local datatable.
            if (!header.sortable === undefined) {
                header.sortable = true;
            }
            if (header.type == 'drag') {
                header.sortable = false;
                header.canhide = false;
                header.canmove = false;
                header.excel = false;
                header.cellClass = "draghandle col-cmd";
                header.class="hdr-cmd";
                header.text = "";
                header.value = "drag";
            }
            if (header.type == 'cmd') {
                header.sortable = false;
                header.canhide = false;
                header.canmove = false;
                header.excel = false;
                header.class="hdr-cmd";
                header.text = "";
                header.value = "cmd";
            }

            if (header.value == 'cmd' || string.isInRange(header.type, "drag", "cmd", "note", "attachment", "location")) {
                if (!header.cellClass) {
                    header.cellClass="col-cmd";
                }
            }
        });
        var pref = this.getDataTableSettings();
        if (!pref) {
            return;
        }

        // Otherwise, apply the preference to the headers
    }
    
    // Configure the columns in this datatable.
    async configure() {

        try {
            await eventbus.dialog.open.promise('configureDatatable', {id: this.name, noSort: true, headers: this.allHeaders, defaultHeaders: this.defaultHeaders})
            this.setHeaders();
        } catch(e) {
            console.error(e);
        } // promise is rejected on cancel. Ignore the error.
    }

    /**
     * Store the visible flag and the index of the columns of the original configuration.
     * This allows the user to go back to 'factory settings' in any stage.
     */
    storeOriginalColumnConfig() {
        this.defaultHeaders = {};
        var self = this;
        (this.allHeaders||[]).forEach( (hdr, ix) => 
            self.defaultHeaders[hdr.value] = {
                visible: undefined === hdr.visible ? true : hdr.visible, 
                ixOrder: ix
            }
        )
    }

    /**
     * Get the number of visible headers
     */
    get visibleHeaderCount() {
        return (this.headers||[]).length;
    }

    /**
     * Hide the checkboxes
     */
    onStopCheckbox() {
        this.checkboxed = false;
    };
    
    /**
     * Show the checkboxes
     */
    onStartCheckBox(item, bulkAction) {
        this.stateCheckAll = false;
        this.checkAll(false);
        if (item) {
            item.checked = true;
        }
        this.checkboxed = true;
        this.bulkAction = bulkAction;
    };
    /**
     * follow the checkAll value but don't change it.
     */
    onFollowCheckAll(){
        this.checkAll(this.stateCheckAll)            
    };
    checkAll(b) {
        if (undefined === b) {
            b = true;
        }
        for (var n = 0; n < this.items.length; n++) {
            this.items[n].checked = !!b;
        };
    }
    get selectedCount() {
        var cnt = 0;
        this.items.forEach( (item) => cnt+=(item.checked?1:0));
        return cnt;
    }
    selectedItems() {
        var arr = [];
        this.items.forEach( (item) => { if(item.checked) {arr.push(item);}});
        return arr;
    };
    selectedIDs() {
        var arr = [];
        this.items.forEach( (item) => { if(item.checked) {arr.push(item.id);}});
        return arr;
    };

    jsonToExcel(headers, rows, name) {
        rows = rows || [];
        // convert all sheet items to excel items
        let convertedItems = [];
        for (var n = 0; n < rows.length; n++) {
            var item = rows[n];

            var convertedItem = {};
            for (var prop in headers) { 
                let header = headers[prop];
                var titleField = header.text;
                var valueField = header.value;
                
                let value = item[valueField] || null;
                if (header.excelFmt) {
                    value = header.excelFmt(value, item);
                } else if (string.isInRange('amount', header.type, header.excelType)) {
                    value = Number(value);
                } else if (string.isInRange('numeric', header.type, header.excelType)) {
                    value = Number(value);
                } else if (string.isInRange('date', header.type, header.excelType)) {
                    value = date.fmt.local(value)
                } else if (string.isInRange('datetime', header.type, header.excelType)) {
                    value = datetime.fmt(value)
                } else if (string.isInRange('hhmm', header.type, header.excelType)) {
                    value = time.minutes2hourminutes(value, false)
                }
                convertedItem[titleField] = value;
                
            }
            convertedItems.push(convertedItem);
        }

        var basename = string.coalesce(name, "lijst");
        var filename = basename + ".xlsx";
        const data = XLSX.utils.json_to_sheet(convertedItems);
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, data, basename)
        XLSX.writeFile(wb, filename)
    }

    getRowDataForExcel() {
        return this.items;
    }
    getHeadersForExcel() {
        // We want to export with all headers. 
        // That is because we also want to export hidden headers.
        // By using allHeaders, the column order is preserved. 
        let initialHeaders = this.allHeaders;
        var excelHeaders = {};
        for (var n = 0; n < initialHeaders.length; n++) {
            var header = initialHeaders[n];
            if ( header.excel || undefined === header.excel) {
                excelHeaders[header.value] = header
            }
        }
        return excelHeaders;
    }
    toExcel(name) {
        let rows = this.getRowDataForExcel();
        let headers = this.getHeadersForExcel();
        this.jsonToExcel(headers, rows, name);
    }

    constructor(name, headers, options) {
        options = options || {};
        this.name = name;
        for (var key in options) {
            if (key != "items") {
                this[key] = options[key];
            }
        }
        if (options.dense === false) {
            this.dense = false;
        }
        this.showFooter = options.showFooter||false;
        if (!undefined == options.minimalFooterRows) {
            this.minimalFooterRows = options.minimalFooterRows;
        }
        this.allHeaders = headers || [];
        this.storeOriginalColumnConfig();
        this.setHeaders();
    }
}

export default clsLocalTable;
<template>
    <Dialog :dialog="dlg" :dt="dt">

        <template v-slot:filter>

            <TextField ref="q" dusk="select-search" block max-length="255" placeholder="Zoek..." v-model="dt.filter.q"></TextField>


        </template>
        <template v-slot:item.mdr_status="{ item }">
            <StatusColumn dusk="select-status" :model="item" ></StatusColumn>
        </template>

    </Dialog>
</template>



<script setup>

    import useDataTable from '@app/useDataTable'
    import string from '@lib/string'

    import Form from '@controls/forms/Form'
    import FormColumn from '@controls/forms/FormColumn'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/SelectListDialog'
    import { mandays as api } from '@app/api'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsSelectListDialog'
    import Constants from '@app/consts'
    import {time} from '@lib/date'
    import StatusColumn from '@/views/manday/StatusColumn'
    

    const form = ref(null); 
    const id_optimit_type = Constants.optimit_types.mandays;
    const q = ref(null)

    var headers = [
        { type: 'cmd'},
        { type: "note" },
        { type: "flag" },
		{ text: "Nummer",            value: "mdr_number"},
		{ text: "Status",            value: "mdr_status"},
		{ text: "Periode",           value: "period", type: 'open', action:"manday.open"},
		{ text: "Relatie",           value: "rel_name"},
        { text: "Titel",             value: "mdr_title", type: 'open', action:"manday.open"},
//		{ text: "Factuur",           value: "inv_number"},
		{ text: "Uren",              value: "mdr_total_minutes", fmt: (v) => time.minutes2hourminutes(v, false)},
		{ text: "Gewijzigd",         value: "updated_at",  type: 'datetime', visible: false},
		{ text: "Gewijzigd door",    value: "updated_by",     visible: false},
		{ text: "Aangemaakt",        value: "created_at",  type: 'datetime', xvisible: false},
		{ text: "Aangemaakt door",   value: "created_by",     visible: false},

    ];



    var dt = useDataTable("dt_selectmandays", "manday", api, headers, {
        actionDblClick:null,
        itemName: {
            prefix: 'de',
            single: 'mandagstaat',
            plural: 'mandagstaten',
        }, 
        useCommandPane: false,
        canArchive: false,
        filter: {
            type: '',
            selectfor: '',
            id_relation: null
        }
    })

    let dlg = ref(
        new clsDialog("select_manday", "Selecteer mandagstaat", dt, {
            multiselect: false, 
            maxWidth:"1200px",
            defaultFocus: ()=>q.value
        })).value;
</script>

<template>    
    <v-skeleton-loader type="opti-input" v-if="compSkeleton"></v-skeleton-loader>
    
        <v-text-field v-else
            class="input-hourminutes xtext-right"
            :class="compClass"
            @keydown="keyDown"
            @blur="onBlur"
            @click="onClick"
            hide-details 
            :disabled="compDisabled"
            dense 
            outlined
            :rules="rules"
            :placeholder="compPlaceholder"
            :label="label"
            ref="textfield"
            nov-model="dvalue"
            :value="dvalue"
            >
        </v-text-field>
</template>

<script setup>

import {computed, ref} from 'vue'
import {time} from '@lib/date'

//
////////////////////////////////////////////////////////////////////////////////////////////////////
// Usage:
// import TimeField from '@controls/input/TimeField'
// <TimeField :model="model" v-model="model.mdrr_monday"></TimeField>
//
////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Use this control to enter a timespan in Hours / Minutes. 
// The timespan can be restricted to be less than 24 hours. 
// The model input is in minutes. 0 == 0:00 and 1439 == 23:59
// 
////////////////////////////////////////////////////////////////////////////////////////////////////
//
// The output is either in decimals (e.g. 7,5 hours) when the dec propety is true
// or the output is in HH:MM (e.g. 08:45) when the hm property is true.
//
////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Entering time can be done as a decimal or HH:MM value. 7,5 and 07:30 resolve to the same.
// 
// NOTE:
// Internally, the input is converted to minutes.  
// This means that some decimal values do not resolve to minutes and hence will be changed.
// So, we do not support seconds.
//
// In:      Out:        Internal (minutes)              Out:
// 7        7               7*60                         7
// 0        0               0                            0
// 7,25     7,25            7.25*60                      7,25
// 7,5      7,5             7.5*60                       7,5
// 7,99     7,99            round(7.99 * 69) = 479       `floor(479,60),round((479%60)/60)` => 7,98
// 
////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Implementation Note
//   We do not connect the v-model of the text field directly to the model value.
//   That is because here (in the control), we fix invalid input. 
//   Imaging what would happen if we were connected to the model value directly: 
//    - Somebody types an invalid value:  00:88. 
//    - on Blur, the control fixes this and the corrected value (00:59) is passed back
//      to the model in its minutes value (59). 
//    - All fine until now. 
//    - Now the user types 00:88 again. 
//    - The control fixes up again and passes 59 to the model. 
//    - Now we have a problem. 
//      The model value was 59 already, so it does not change. 
//      Because it does not change, the control is not updated, and just displays 00:88.
//   
//   This is the easiest explainable scenario where things go wrong. 
//   For this reason, we use an internal dummy (dvalue), which we use as direct control binding, and 
//   in parallel, we pass corrected values back to the model.  
//

const props = defineProps({
    value: {
        type: [String, Number]
    },
    disabled: {
        type: [Boolean]
    },
    label: {
        type: String
    },
    placeholder: {
        type: [String],
        default: ''
    },
    rules: {
        type: [Array]
    },
    noBorder: {
        type: [Boolean]
    },
    borderHover: {
        type: [Boolean]
    },
    slimFit: {
        type: [Boolean],
        default: true
    },            
    block: {
        type: [Boolean],
        default: false
    },            
    skeleton: false,
    model: {
        type: [Object]
    },
    decimal: {
        type: [Boolean],
        default: true
    },            
    hm: {
        type: [Boolean],
        default: false
    },       
    // When true, value can not be higher than 24 hours
    max24: {
        type: [Boolean],
        default: true
    },            
    
})

const compPlaceholder = computed({
    get() {
        if (props.label) {
            return null;
        }
        return props.placeholder;
    }
})
const compClass = computed({
    get() {
        var border = props.noBorder ? "no-border" : ""; 
        var slimfit = ((!props.block) && props.slimFit) ? "slim-fit" : ""; 
        var borderHover = props.borderHover ? "border-hover" : ""; 

        return `${border} ${borderHover} ${slimfit}`;
    }
})
const compSkeleton = computed({
    get() {
        if (props.skeleton) {
            return true;
        }
        if (!props.model) {
            return false;
        }
        return props.model.isDataLoading;
    }
})
const compDisabled = computed({
    get() {
        if (props.disabled) {
            return true;
        }
        if (!props.model) {
            return false;
        }
        return props.model.disabled;
    }
})

const emit = defineEmits(['input'])

////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Use dvalue to get the value for display the representation (H,M or HH:MM) and set the value in 
// the internal (minutes) format.
//
// get: returns "07:30"    when property 'hm' is true
//    : returns "7,5"      otherwise
// 
// set(v): emits the date in minutes format
//       : so, v MUST be in minutes since midnight. 
//
////////////////////////////////////////////////////////////////////////////////////////////////////
const dvalue = computed({
    get() {
        if (!props.value) {
            return props.hm?"00:00":"0";
        }
        if (props.hm) {
            return time.minutes2hourminutes(props.value, this.max24);
        } else {
            return time.minutes2hourdecimal(props.value, this.max24)
        }
    },
    // v is in minutes
    set: function(v) {
        emit('input', v)
    }	
})

const textfield = ref();

/**
 * In:               Out (minutes)           
 * 7                 7*60                    
 * 0                 0                       
 * 7,25              7.25*60                 
 * 7,5               7.5*60                  
 * 7,99              round(7.99 * 69) = 479  
 * 7.25              7.25*60                 
 * 
 * @param {*} v 
 */
var fromFractional = function(v) {    
    if (!v) {
        return null;
    } 
    var num = Number(`${v}`.replace(',', '.'));
    if (isNaN(num)) {
        return null;
    }
    var minutes = Math.round(num*60); 
    if (props.max24) {
        return Math.min(minutes, 60*24);
    }
    return minutes;
}
/**
 * In:               Out (minutes)           
 * 7                 null                    
 * 0                 null                       
 * 7,25              null                 
 * 7,5               null                  
 * 7:40              7*60+40  
 * 7:99              7*60+59  
 * 
 * @param {*} v 
 */
 var fromHM = function(v) {
    if (!v) {
        return null;
    } 
    var arrParts = `${v}`.split(':');
    if (!arrParts || arrParts.length != 2) {
        return null;
    }
    var h = Number(arrParts[0]);
    var m = Number(arrParts[1]);
    if (isNaN(h) || isNaN(m)) {
        return null;
    }
    m = Math.min(59,m);
    var minutes = h * 60 + m;
    if (props.max24) {
        return Math.min(minutes, 60*24);
    }

    return minutes;
}


function onBlur(evt) {
    var v = evt?.target?.value;
    if (!v) {
        dvalue.value = 0;
    } else {
        // Convert '7,5' or '7:30' or '7' to minutes
        var minutes = fromFractional(v) || fromHM(v);
        console.log("onBlur", v, minutes);
        dvalue.value = minutes;
        if (!minutes) { 
            // scenario: control is empty. THen fill something invalid --> control must be cleared.
            textfield.value.internalValue = props.hm?"00:00":"0";
        } else { 
            // scenario, control contains '7,5'
            // User types 7:30, which is the same in minutes. As reactiveness is not 
            // triggered (because the value in minutes does not change), the display value will not be updated.
            if (dvalue.value != textfield.value.internalValue) {
                textfield.value.internalValue = dvalue.value;
            }
        }
    }
//    setTimeout(()=>open.value = false, 100)
}

function onClick(event) {
    var target = event?.target; 
    if (!target) {
        return;                    
    }
    if (target.disabled) {
        return;                    
    }
    if (target.select) {
        target.select();
    }
}

function keyDown($event) {
    let keyCode = $event.keyCode ? $event.keyCode : $event.which;
    // 38: key up, 40: key down
    if (!$event || !$event.target) {
        return;
    }
    if (keyCode == 40) { // Key Down --> open the picker
        var v = textfield.value.internalValue // When typing in the field, the value is not yet propagated to the model
        var minutes = fromFractional(v) || fromHM(v);
        if (minutes > 60)  {
            dvalue.value = minutes - 60;
        }       
    } else if (keyCode == 38) { // Key Up
        var v = textfield.value.internalValue // When typing in the field, the value is not yet propagated to the model
        var minutes = fromFractional(v) || fromHM(v);
        if (minutes < (1440-60))  {
            dvalue.value = minutes + 60;
        }       
        
    } else if (keyCode == 27) { // Escape
        
    } else {
        
    }
    // $event.preventDefault();
    // $event.stopPropagation();
}    
</script>

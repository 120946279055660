<template>
    <Dialog :dialog="dlg" xno-sub-title no-content-padding>

        <Datatable :dt="dt" class="full-height">

        </Datatable>

    </Dialog>
</template>



<script setup>
    import useDataTable from '@app/useDataTable'
    import Datatable from '@datatable/Datatable'
    import { salesinvoice_logs as api } from '@app/api'
    import { ref, onMounted, onUnmounted } from 'vue'
    import clsDialog from '@cls/clsDialog'
    import Dialog from '@shared/ui/dialogs/ActionDialog'

/**
 * Declare the data table first as 
 */
    var fmtType = function(a,b,item) {
        return (item.accountancy)?"Accountant":"Betaling";
    }

    var headers = [
        { text: "Type",                     value: "type"},
        { text: "Gebruiker",                value: "username", },
		{ text: "Tijdstip",                 value: "created_at",    type: 'datetime',   visible: true},
		{ text: "Geaddresseerde",           value: "recipients",    visible: true, fmt: (v,V,item) => v||"-"},
		{ text: "Referentie",               value: "id_link",    fmt: (v,V,item) => v||"-"},
    ]

    var dt = useDataTable("dt_salesinvoice_log", "salesinvoice_history", api, headers, {
        itemName: {
            prefix: 'de',
            single: 'logregel',
            plural: 'logregels',
        }, parent: {
            id_invoice: null // model.id
        },
        noAutoSearch: true, // Search not until the parent id is set.
        canArchive: false
    })


    class clsPModel {
        id = null;
        inv_number  = null;

        get subTitle() {
            if (this.isLoading || this.isDataLoading) {
                return 'Laden...';
            }
            return `Factuur ${this.inv_number}`;
        }
        async load(id, params) {
            params = params ||{}
            this.inv_number  = params.inv_number;            
            this.id = id;
            console.log('setParent', {id_invoice: id}, true)
            dt.setParent({id_invoice: id}, true); // true: search
        }
    }

    let model = ref (new clsPModel());

    let dlg = ref(
        new clsDialog("salesinvoice_history", "Factuurgeschiedenis", model, [], {            
            setKeyOnOpen: true,
            icon: 'log',
            maxWidth:'1200px', 
            showNote: false,
            showAttachment: false,
            save:false,
            cancel:'Sluit'
        } )).value;  //TODO

    window.dtl = dt;
</script>
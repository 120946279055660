import axios from "@app/axios";
import eventbus from '@app/eventbus'
import user from '@app/user'
import noty from '@shared/lib/noty'


class clsHttp {
    // httpclient is our contained instance of Axios.
    httpClient = null;

    // When hideError, an error (e.g. a validation error or a 500 error) is not propagated to the UI.
    // Example use case:
    // - for purchase invoices, a PDF viewer is shown alongside a form. 
    //   Now, the PDF can not be loaded but the form data is loaded. It is confusing when a 'standard' message is shown and the PDF is
    //   just showing blank. 
    // 
    constructor() {
        this.httpClient = axios.createClient(true); // Create an axios http client with error handling
    }

    /**
     * Get the options we provide in the server calls.
     * Most importantly, specific headers.
     * 
     * @param {} url 
     * @returns 
     */
    _getOptions(url) {
        let headers = {};
        // In this context, 'user' might seem odd. Consider it the 'session'. 
        if (user.getHeaders) {
            headers = user.getHeaders();
        }
        return {headers: headers}
    }


    /**
     * Download a piece of data from the server. 
     * This can be blobdata like a pdf document but it could as well be anything else.
     * 
     * returns: {
     *      data: [],                           // byte array
     *      contentType: 'application/pdf'
     *      filename: 'invoice.pdf'
     * }
     * 
     * @param {} url 
     */
    _downloadBlob(url, hideError) {
        let options = this._getOptions(url);

        if (window._m && window._m.dbg && window._m.dbg.pdf) {
            options.fmt = 'html';
            url = `${url}?fmt=html`;
        } else {
            options.responseType ='arraybuffer';
        }
        if (hideError) {
            options.hideError=hideError;
        }
        return this.httpClient.get(url, options)
            .then((response) => {

                if (window._m && window._m.dbg && window._m.dbg.pdf) {
                    this._downloadBlobToClient(response.data, "text/html", "debugoutput.html");
                    return;
                }

                let contentType = response.headers["content-type"] || 'text/plain';

                // Get the file name from the content disposition.
                let filename = null;
                let contentDisposition = response.headers["content-disposition"] || response.headers["Content-Disposition"] || "";
                if (contentDisposition && contentDisposition.match) {
                    // contentDisposition: 
                    //              'attachment; filename=testtext.txt' 
                    //              'attachment; filename="testtext.txt"' 
                    // Note the optional (double)quotes.
                    // var expr = /filename=\"(.*)\"/g;   does not work because of the quotes.
                    var expr = /filename=[\"]?(.*\.[a-zA-Z]+)/g        
                    var arr = expr.exec(contentDisposition);                
                    if (arr && arr.length == 2) {
                        filename = arr[1];
                    }
                }          
                return {
                    data: response.data,
                    contentType: contentType,
                    filename: filename
                }
            });
    }

    /**
     * Download a piece of data to the client. 
     * Most obvious use case: download a document (e.g. pdf) from the server. Then download it to the client.
     * 
     * @param {} blobdata 
     * @param {*} contentType 
     * @param {*} filename 
     */
    _downloadBlobToClient(blobdata, contentType, filename) {

        blobdata = blobdata || [];
        contentType = contentType || "text";
        filename = filename || "file";

        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([blobdata], {type: contentType})

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob)
            return; // done.
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob)
        var link = document.createElement('a')
        link.href = data;
        link.download = filename;
        link.click()
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data)
        }, 100)
    }

    // For a get, if the url has placeholders, replace the provided params in the url. 
    // E.g. url: /download/{id}, params: {id: 123456}
    // ===> url: /download/123456, params: {}
    processGetParams(url, params) {
        let newUrl = url;
        for (var key in params) {
            let tmpUrl = newUrl.replace(`{${key}}`, params[key]);
            if (tmpUrl != newUrl) {
                delete params[key];
            }
            newUrl = tmpUrl;
        }
        url = newUrl;

        return [url, params];
    }
    ///////////////////////////////////////////////////////////////////////////////
    //
    // The Api
    //
    ///////////////////////////////////////////////////////////////////////////////

    /**
     * Execute a get request
     * 
     * @param {*} url 
     * @param {*} params 
     * @returns 
     */
    get(url, params) {      
        [url, params] = this.processGetParams(url, params);

        let options = this._getOptions(url);
        return this.httpClient.get(url, {params: params, headers: options.headers})
    }

    /**
     * Execute a post request
     * @param {*} url 
     * @param {*} params 
     * @param {*} extraOptions 
     * @returns 
     */
    post(url, params, extraOptions) {        
        let options = this._getOptions(url);
        if (extraOptions) {
            for (var n in extraOptions) {
                options[n] = extraOptions[n];
            }
        }
        return this.httpClient.post(url, params, options)
    }

    /**
     * Execute a put request
     * @param {*} url 
     * @param {*} params 
     * @param {*} extraOptions 
     * @returns 
     */
    put(url, params, extraOptions) {        
        let options = this._getOptions(url);
        if (extraOptions) {
            for (var n in extraOptions) {
                options[n] = extraOptions[n];
            }
        }
        return this.httpClient.put(url, params, options)
    }
    

    /**
     * Upload data to a server
     * @param {} url 
     * @param {*} params 
     * @param {*} onProgress 
     * @returns 
     */
    upload(url, params, onProgress) {
        let options = this._getOptions(url);
        options.headers["Content-Type"] = "multipart/form-data";
        options.onUploadProgress = onProgress;
        return this.httpClient.post(url, params, options);
    }

    // The typical http requests we execute: 
    // 1) Post data and retrieve JSON data                              --> default GET request
    // 2) Get with ID and retrieve JSON data                            --> default POST request
    // 3) Get with ID and retrieve BLOB data (e.g. PDF, or xml data)    --> GET request with ArrayBuffer response type
    // 4) Post data and retrieve BLOB data (e.g. PDF, or xml data)      --> Post request with ArrayBuffer response type    

    // An optional supportive actions for 3) and 4) above is: 
    // a) download blobdata to the client (so the user can store it or open it).

    _isRawDataDebugEnabled() {
        return window._m && window._m.dbg && window._m.dbg.pdf;
    }
    /**
     * Download a piece of data from the server. 
     * This can be blobdata like a pdf document but it could as well be anything else.
     * 
     * returns: {
     *      data: [],                           // byte array
     *      contentType: 'application/pdf'
     *      filename: 'invoice.pdf'
     * }
     * 
     * @param {} url 
     */
    _loadRawData(url, bGet, params, extraOptions ) {
        let options = this._getOptions(url);
        options.responseType ='arraybuffer'; // Raw data --> download as a buffer
        for (var key in (extraOptions||{})) {
            options[key]=extraOptions[key];
        }
        // When debugging, return in html whenever it is supported by the server implementation. 
        params = params ||{};
        if (this._isRawDataDebugEnabled()) {
            params.fmt = 'html';
        }
        // For a get, if the url has placeholders, replace the provided params in the url. 
        // E.g. url: /download/{id}, params: {id: 123456}
        // ===> url: /download/123456, params: {}
        if (bGet) {
            [url, params] = this.processGetParams(url, params);
        }

        let fn = () => { 
            return bGet ? this.httpClient.get(url, {params: params, ...options}) 
                        : this.httpClient.post(url, params, options);
        }
        return fn()
            .then((response) => {
                let contentType = response.headers["content-type"] || 'text/plain';

                // Get the file name from the content disposition.
                let filename = null;
                let contentDisposition = response.headers["content-disposition"] || response.headers["Content-Disposition"] || "";
                if (contentDisposition && contentDisposition.match) {
                    // contentDisposition: 
                    //              'attachment; filename=testtext.txt' 
                    //              'attachment; filename="testtext.txt"' 
                    // Note the optional (double)quotes.
                    // var expr = /filename=\"(.*)\"/g;   does not work because of the quotes.
                    var expr = /filename=[\"]?(.*\.[a-zA-Z]+)/g        
                    var arr = expr.exec(contentDisposition);                
                    if (arr && arr.length == 2) {
                        filename = arr[1];
                    }
                }        
                // If the debug flag was set and the contentType is html, download the data to the client.
                if (this._isRawDataDebugEnabled() && (contentType||"").indexOf("html") >=0 ) {
                    this._downloadBlobToClient(response.data, contentType, filename||"debugoutput.html");    
                    return {}; //  the data is of no further use.
                }
                return {
                    data: response.data,
                    contentType: contentType,
                    filename: filename
                }
            });
    }


    /**
     * Retrieve raw data (non-Json data) via a GET request
     * 
     * Returns a promise which returns: {
     *      data: [],                           // byte array
     *      contentType: 'application/pdf'
     *      filename: 'invoice.pdf'
     * }
     * 
     * @param {*} url     - the url
     * @param {*} params  - parameters which will be passed to the query string
     * @param {*} options - extra options for Axios &| hideError 
     * @returns 
     */
    async getRaw(url, params, options) {    
        console.log('getRaw', url, params. options)
        return await this._loadRawData(url, true, params, options);
    }
    /**
     * Retrieve raw data (non-Json data) via a POST request
     * 
     * Returns a promise which returns: {
     *      data: [],                           // byte array
     *      contentType: 'application/pdf'
     *      filename: 'invoice.pdf'
     * }
     * 
     * @param {*} url     - the url
     * @param {*} params  - parameters provided in the post body
     * @param {*} options - extra options for Axios &| hideError 
     * @returns 
     */
    async postRaw(url, params, options) {    
        console.log('postRaw', url, params. options)
        return await this._loadRawData(url, true, params, options);
    }

    /**
     * Retrieve raw data (non-Json data) via a GET request and download the result to the client.
     * 
     * Returns a promise which returns: {
     *      data: [],                           // byte array
     *      contentType: 'application/pdf'
     *      filename: 'invoice.pdf'
     * }
     * 
     * @param {*} url     - the url
     * @param {*} params  - parameters which will be passed to the query string
     * @param {*} options - extra options for Axios &| hideError 
     * @param {*} filename - optional filename. Will be deduced from server result when not provided.
     * @returns 
     */
    async downloadGetRaw(url, params, options, filename) {    
        console.log('downloadGetRaw', url, params. options, filename)
        let result = await this._loadRawData(url, true, params, options);
        this._downloadBlobToClient(result.data, result.contentType, filename||result.filename);    
        return result;
    }

    /**
     * Retrieve raw data (non-Json data) via a POST request and download the result to the client.

     * Returns a promise which returns: {
     *      data: [],                           // byte array
     *      contentType: 'application/pdf'
     *      filename: 'invoice.pdf'
     * }
     * 
     * @param {*} url     - the url
     * @param {*} params  - parameters which will be passed to the query string
     * @param {*} options - extra options for Axios &| hideError 
     * @param {*} filename - optional filename. Will be deduced from server result when not provided.
     * @returns 
     */
    async downloadPostRaw(url, params, options, filename) {    
        console.log('downloadPostRaw', url, params. options, filename)
        let result = await this._loadRawData(url, false, params, options);
        this._downloadBlobToClient(result.data, result.contentType, filename||result.filename);    
        return result;
    }

    /**
     * Download raw data to the client. Data can be xml, pdf, whatever.
     * The browser will notify the user with a download or open action, whatever is appropriate for the filetype and browser settings.
     * 
     * @param {*} rawData 
     * @param {*} contentType 
     * @param {*} filename 
     * @returns - 
     */
    downloadRawDataToClient(rawData, contentType, filename) {
        return this._downloadBlobToClient(rawData, contentType, filename);
    }
    



    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // OBSOLETE
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /**
     * A post action, specificly designed to retrieve PDF documents back. 
     * So, as an example, post the configuration of an invoice and retrieve a PDF as a result.
     * When _m.dbg.pdf == true, html is expected back. This is for debugging PDF documents. 
     * It skips the last step of converting to PDF.
     * 
     * @param {*} url 
     * @param {*} params 
     * @returns 
     */
            postPDF(url, params) {
                console.error('OBSOLETE - http.postPDF')
                params = params || {};
                let options = this._getOptions(url);
                if (window._m && window._m.dbg && window._m.dbg.pdf) {
                    params.output = 'html';
                } else {
                    options.responseType ='arraybuffer';
                }
                return this.httpClient.post(url, params, options);
            }
            
            /**
             * Download a document from the server via an url. 
             * This is a 2 step process. 
             * 1 is to download the data from the server. 
             * 2 is to download the blob to the client. 
             * 
             * @param {*} url 
             * @param {*} filename 
             */
            download(url, filename) {
                console.error('OBSOLETE - http.download')
                return this._downloadBlob(url)
                .then( (result) => {     
                    result = result || {};             
                    var name = filename || result.filename;
                    console.log('dwnl', result, name)
                    this._downloadBlobToClient(result.data, result.contentType, name);
                });
            }
        
            // Download data from the server    
            // Note that when the (optional) hideError parameter is set, on error, the user is not notified
            // So, no event is posted for displaying the error in a dialog. 
            // The use case for this is that in e.g. a PDF viewer showing the error in a popup is confusing. 
            // Instead, the pdf viewer can handle the error by displaying a custom error document or whatever.
            //
            // returns: {
            //      data: [],                           // byte array
            //      contentType: 'application/pdf'
            //      filename: 'invoice.pdf'
            // }
            //   
            downloadData(url, hideError) {
                console.error('OBSOLETE - http.downloadData')
                return this._downloadBlob(url, hideError);
            }
        










}

export default clsHttp;

import { clsModel, fnCreate } from '@cls/clsModel'
import { salesinvoices as api } from '@/app/api'
import string from '@lib/string'

// We operate on a sales invoice, no special model name for sending an invoice. 
var modelName = "salesinvoice_send";
const fields = ['id', 'subject', 'body', 'to', 'cc', 'recipients', 'address', 'attachments', 'allattachments', 'mergeattachments', 'inv_number', 'id_optimit_type'];

class clsSalesInvoiceSend extends clsModel {

    subject = null;
    body = null;
    to = null;
    cc = null;
    recipients = [];    
    attachments = [];
    allattachments = [];
    inv_number = null;
    digital = true;
    address = [];
    id_optimit_type = null;

    mergeattachments = false;

    attach(attachment) {
        this.attachments.push(attachment);
    }
    unattach(attachment) {
        this.attachments = this.attachments.filter( (item) => item != attachment);
    }
    async refreshAttachments() {
        this.isDataLoading = true;
        try {
            var currentAttachments = this.allattachments.map( (x) => x.id);
            
            var result = await this.api.loadSendData(this.id);
            this.allattachments = result.data.allattachments ||[];
            this.allattachments.forEach( (att) => {
                if (!currentAttachments.find ( (x) => x == att.id)) {
                    // new attachment
                    this.attachments.push(att);
                }
            })
        }
        finally {
            this.isDataLoading = false;
        }
    }
     
    apiLoad(id, params) {
        return this.api.loadSendData(id, params);
    }
    async apiSave(model) {
        if (!this.digital) {
            await this.api.downloadSend(this.id);        
            return { id: this.id} // The data is downloaded to the client. 
                                  // We don't want the downloaded data to be sent as followup event parameters.
        }
        return this.api.emailSend(model);
    }
    onBeforeOpen() {
        this.digital = true;
    }
    /**
     * Override this method to handle default notification or to not notify
     * @param {*} resultData 
     */
    onSaveNotification(resultData, msg) {
        msg = this.digital ? "De factuur is verzonden" : "De factuur is gedownload";
        super.onSaveNotification(resultData, msg);
    }

    /**
     * When the invoice is sent for the first time, statistics need to be refreshed.
     */
    onAfterSave(resultData) {
        if (!this.inv_number) {
            this.sendStatsRefreshEvent();   
        }
    }

    fill(data) {
        data = data || {};
        data.digital = true;
        data.attachments = data.attachments ||[];
        data.allattachments = data.allattachments ||[];
        data.address = data.address || [];

        this.digital = true;        
        this.mergeattachments = false;

        return super.fill(data);
    }
    
    toJSON() {        

        var result = this.propsToJSON(['id', 'subject', 'body', 'to', 'cc', 'attachments', 'mergeattachments']);    
        result.attachments = this.attachments.map( (x) => x.id);
        return result;
    }

    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          mandatoryFields: ['subject', 'body'],

          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsSalesInvoiceSend, 'clsSalesInvoiceSend');

<template>
        <v-card class="bg-card">
            <v-card-text>                
                <v-row dense>
                    <v-col sm="6" md="6" lg="6" xl="4" class="pr-4">
                            <FormRow v-if="feature.canProject" col-label="3" label="Project">    
                                
                                <ProjectPicker :disabled="model.isFixedProject" :filter="{'selectfor':'invoice'}" :fieldMap="projectFieldMap" :model='model' :valueModel="model" :rules='model.rules.id_project' v-model='model.id_project'>
                                    <template v-slot:append v-if="model.cntProjects>1">
                                        <span title="In de regels zijn verschillende projecten geselecteerd.">
                                            <v-chip small xoutlined color="warning" style="height:18px;" class="mr-2 mt-1 py-0" >{{ model.cntProjects }}</v-chip> 
                                        </span>
                                    </template>
                                </ProjectPicker>
                            </FormRow>
                            <FormRow col-label="3" label="Titel">    
                                <TextField max-length="255" placeholder="Factuur titel" :model="model" :rules="model.rules.inv_name" v-model="model.inv_name"></TextField>
                            </FormRow>
                            <FormRow col-label="3" label="Relatie">    
                                <RelationCombo sales :disabled="model.isOpenOrLater" :model="model" :rules="model.rules.id_relation" v-model="model.id_relation"></RelationCombo>
                            </FormRow>

                            <FormRow col-label="3" label="Adres">    
                                <TextField disabled :model="model" v-model="model.rel_address">
                                    <template v-slot:append-outer>        
                                    <ActionMenu>
                                        <ActionButton comboContextButton icon='menu-vertical'></ActionButton>
                                        <template v-slot:dropdown>
                                            <slot name="contextMenu">
                                                <ActionMenuItem :disabled="!model.id_relation" action='relation.open' @click="dlg.open('relation', {id: model.id_relation})"></ActionMenuItem>
                                            </slot>
                                        </template>
                                    </ActionMenu>
                                    </template>
                                </TextField>
                            </FormRow>
                            <FormRow col-label="3" label="T.a.v.">    
                                <RelationContactCombo placeholder="Contactpersoon (Optioneel)" :disabled="model.isOpenOrLater||!model.id_relation" :model="model" :rules="model.rules.id_address" :id_parent="model.id_relation" v-model="model.id_person"></RelationContactCombo>
                            </FormRow>
                    </v-col>
                    <v-col sm="6" md="6" lg="6" xl="4">
                            <FormRow col-label="3" label="Referentie">    
                                <TextField :disabled="model.isOpenOrLater" ref="reference" max-length="255" placeholder="Optioneel" :rules='model.rules.inv_reference' :model="model"  v-model="model.inv_reference"></TextField>
                            </FormRow>
                            <FormRow col-label="3" label='Factuur / vervaldatum'>
                                <v-row dense>
                                    <v-col>
                                        <DatePicker :disabled="model.isOpenOrLater" :model="model" :rules='model.rules.inv_date' v-model='model.inv_date'></DatePicker>
                                    </v-col>
                                    <v-col>
                                        <DatePicker :offsetSource="model.inv_date" :disabled="model.isOpenOrLater" :model="model" :rules='model.rules.inv_exp_date' v-model='model.inv_exp_date'></DatePicker>
                                    </v-col>
                                </v-row>
                            </FormRow>
                             <FormRow col-label="3" label="Boekdatum" v-if="feature.canSalesBookdate">
                                <DatePicker :disabled="model.isSentToAccountancy" :model="model" :rules='model.rules.inv_book_date' v-model='model.inv_book_date'></DatePicker>
                            </FormRow>
                            <FormRow col-label="3" label="BTW Verlegd">            
                                <Checkbox switcher :disabled="model.isOpenOrLater" :model="model" :rules='model.rules.inv_vat_shifted' v-model="model.inv_vat_shifted"></Checkbox>
                            </FormRow>
                            <FormRow v-if="feature.canConsumerPrice" col-label="3" label="Regelprijs">            
                                <Checkbox :disabled="model.isOpenOrLater||model.hasLines" @click="onClickUseConsumerPrice" switcher :model="model" v-model="model.useAmountsInclVat">
                                </Checkbox>
                                <span style="position:relative; top:-5px;" :class="{'text-error':model.useAmountsInclVat}" class="text-italic">Voer regelprijs inclusief BTW in</span>
                            </FormRow>
                            <FormRow v-if="feature.canMolliePayment" col-label="3" label="Stuur betaal-link mee">            
                                <Checkbox switcher :model="model" v-model="model.use_payment_link">
                                </Checkbox>
                                <span style="position:relative; top:-5px;" class="text-italic">op pdf en in factuur e-mail</span>
                            </FormRow>

                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
</template>



<script setup>
    
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import RelationCombo from '@controls/combo/Relation'
    import DatePicker from '@controls/input/DatePicker'
    import {computed, ref} from 'vue'
    import ProjectPicker from '@controls/picker/Project'
    import Checkbox from '@controls/check/Checkbox.vue'
    import RelationContactCombo from '@controls/combo/RelationContact'
    import feature from "@app/features";
    import ActionButton from '@controls/buttons/ActionButton'
    import ActionMenu from '@controls/menu/ActionMenu.vue'
    import ActionMenuItem from '@controls/menu/ActionMenuItem.vue'
    import dlg from '@app/dlg'
    import noty from '@shared/lib/noty'

    const props = defineProps({
        model: {
            type: [Object]
        },
    });

    function onMollieWarning() {
        noty.alert("De instellingen voor de mollie koppeling zijn nog niet ingevuld. De betaal-link kan daarom niet worden meegestuurd.<br><br>Ga naar instellingen - mollie om de koppeling te configureren.");
    }

    // Our fields which come from projects. We want them overridden when a project is selected.
    let projectFieldMap = {pro_reference: 'inv_reference', pro_name: 'inv_name', pro_number: 'number', id_relation: "id_relation"};
    
    function onClickUseConsumerPrice(a,b,c) {
        if (!(props.model.isOpenOrLater||props.model.disabled) && props.model.hasLines) {
            noty.alert(`Er staan al regels in de factuur.<br>Alvorens de regelinvoer type te wijzigen dient u eerst alle regels te verwijderen.`);
        }      
    }

    const reference = ref(null);
    window.reffie = reference;
</script>

<template>
    <div>
        <v-data-table
            :mobile-breakpoint="0"
            @dblclick:row="(evt, item)=>dt.onDblClick(evt, item)" 
            @click:row="(itemData, slotData, evt)=>dt.onClick(itemData, slotData, evt)" 
            fixed-header
            :dense="dt.dense"
            :headers="dt.headers"
            :items="dt.items"
            :options.sync="dt.options.sync"
            :server-items-length="dt.totalItems"
            :loading="dt.loading"
            :no-data-text="dt.noDataText"
            :footer-props="dt.footer"
            :loadingText="dt.loadingText"
            :hideDefaultFooter="dt.hideDefaultFooter"
            :sort-by.sync="dt.sortBy"
            :sort-desc.sync="dt.sortDesc"
            item-key="oid"
            :class="compClass"
        >

            <template v-slot:header.cmd="{ header }">
                <Checkbox class="mt-0 ml-0" v-if="dt.mode=='default' && dt.checkboxed" v-model="dt.stateCheckAll" @click="dt.onFollowCheckAll()"></Checkbox>
                <span v-else-if="(!dt.checkboxed || dt.mode!='default') && dt.canConfigureColumns">

                    <span @click="()=>dt.configure()" class="hdr-cmd cursor-pointer" >
                        <Icon class="" small type="setting"></Icon>
                    </span>
                </span>
            </template>
            <template v-for="(header, index) in columnHeaders" v-slot:[`header.${header.value}`]="{item}" >   
                <slot :name="`header.${header.value}`" :header="header">
                    {{header.text}}
                </slot>
            </template>


            <template v-slot:footer.prepend>
                <slot name="footer.prepend"></slot>
            </template>
            <template v-slot:footer_or_foot_i_dont_know>
                <tfoot><tr>
                    <td>TFOOT</td>
                    </tr>
                </tfoot>
            </template>

            <template v-slot:item.cmd="{ item }">
                <Checkbox v-if="dt.checkboxed" v-model="item.checked"></Checkbox>
                <slot name="single-select-cmd" v-else-if="dt.mode =='singleselect'" :item="item" :datatable="dt">
                    <ActionButton dusk="single-select" link-button  @click="(evt) => dt.onSingleSelect(item)">
                        <slot name="select" :item="item" :datatable="dt">
                            <span><Icon type='select-from-list'></Icon></span>
                        </slot>
                    </ActionButton>
                </slot>
                <IconMenu v-else> 
                    <slot name="menu" :item="item" :datatable="dt"></slot>
                </IconMenu>
            </template>

            <template v-for="(header, index) in columnHeaders" v-slot:[`item.${header.value}`]="{item}" >   
                <span :class="header.fnClass?(header.fnClass(item[header.value], `${item[header.value]}`.toUpperCase(), item)):''" :key="index">
                <span v-if="!!header.emptyText&&!item[header.value]" :key="index">
                    {{header.emptyText}}
                </span>
                <span v-else-if="header.type=='timestamp'" :key="index">
                    <TimestampColumn :value="item[header.value]"></TimestampColumn>
                </span>
                <span v-else-if="header.type=='datetime'" :key="index">
                    <DateTimeColumn :isUtc="header.isUtc" :value="item[header.value]"></DateTimeColumn>
                </span>
                <span v-else-if="header.type=='attachment'" :key="index">
                    <AttachmentColumn :count="item.attachmentCount" @click="()=>onAttachment(item, header)"></AttachmentColumn>
                </span>                
                <span v-else-if="header.type=='location'" :key="index">
                    <LocationColumn :address="item"></LocationColumn>
                </span>                

                <span v-else-if="header.type=='date'" :key="index">
                    <DateColumn :value="item[header.value]"></DateColumn>
                </span>                
                <span v-else-if="header.type=='expirydate'" :key="index">
                    <ExpiryDateColumn :value="item[header.value]"></ExpiryDateColumn>
                </span>                
                <span v-else-if="header.type=='flag'" :key="index">
                    <FlagColumn :flagType="header.flagType" :model="item" v-model="item.flag" :id_optimit_type="header.id_optimit_type||item.id_optimit_type" :id_entity="item[header.id_field||'id']">
                    </FlagColumn>         
                </span>
                <span v-else-if="header.type=='note'" :key="index">
                    <NoteColumn :noteType="header.noteType" v-if="!header.when||header.when(item)" :value="item[header.value]"  @click="()=>onNote(item, header)"></NoteColumn>
                </span>
                <span v-else-if="header.type=='email'" :key="index">
                    <EmailColumn :value="item[header.value]"></EmailColumn>
                </span>
                <span v-else-if="header.type=='phone'" :key="index">
                    <PhoneColumn :value="item[header.value]"></PhoneColumn>
                </span>
                <span v-else-if="header.type=='amount'" :key="index">
                    <AmountColumn :value="item[header.value]" :crnc_code="item[header.crnc_field]"></AmountColumn>
                </span>
                <span v-else-if="header.type=='percentage'" :key="index">
                    <PercentageColumn :value="item[header.value]"></PercentageColumn>
                </span>
                <span v-else-if="header.type=='check'" :key="index">
                    <BoolYesNoColumn v-if="header.fnTransform" check="true" :value="header.fnTransform(item[header.value])"></BoolYesNoColumn>                    
                    <BoolYesNoColumn v-else check="true" v-model="item[header.value]"></BoolYesNoColumn>
                </span>
                <span v-else-if="header.type=='priority'" :key="index">
                    <PriorityColumn v-model="item[header.value]"></PriorityColumn>
                </span>
                <span v-else-if="header.type=='yesno'" :key="index">
                    <BoolYesNoColumn check="false" v-model="item[header.value]"></BoolYesNoColumn>
                </span>
                <span v-else-if="header.type=='hhmm'" :key="index">
                    <HourMinuteColumn v-model="item[header.value]"></HourMinuteColumn>
                </span>
                <span v-else-if="header.type=='daysfromnow'" :key="index">
                    <DaysFromNow :value="item[header.value]"></DaysFromNow>
                </span>
                <span v-else-if="header.fmt" :key="index">
                    <span v-if="header.type=='open'" class="cursor-pointer primary--text" :color="'primary'" @click="()=>onOpen(item, header)">
                        {{ header.fmt(item[header.value], `${item[header.value]}`.toUpperCase(), item)}}
                    </span>
                    <span v-else>
                        {{ header.fmt(item[header.value], `${item[header.value]}`.toUpperCase(), item)}}
                    </span>
                </span>
                <span v-else-if="header.type=='open'" :key="index">
                    <span class="cursor-pointer primary--text" :color="'primary'" @click="()=>onOpen(item, header)">
                        {{ coalesce(item, header.value, header.alternateText) }}
                    </span>
                </span>
                <slot v-else :name="columnSlotName(header)" :item="item">
                    {{item[header.value]}}
                </slot>
                </span>
            </template> 


        </v-data-table>
        <CommandPane v-if="dt.useCommandPane && dt.checkboxed" :selectedcount="dt.selectedCount" @close="dt.onStopCheckbox()">
            <template v-slot:commands>
                <slot name="commands">
                    <ActionButton dusk="btn-bulk-remove" main primary icon="remove" v-if="dt.bulkAction=='remove'"  @click="dt.onBulkRemove()" :disabled="!dt.selectedCount" >Verwijder</ActionButton>
                    <ActionButton dusk="btn-bulk-archive" main primary icon="archive" v-if="dt.bulkAction=='archive'"  @click="dt.onBulkArchive()" :disabled="!dt.selectedCount" >Archiveer</ActionButton>
                    <ActionButton dusk="btn-bulk-unarchive" main primary icon="unarchive" v-if="dt.bulkAction=='unarchive'"  @click="dt.onBulkUnArchive()" :disabled="!dt.selectedCount" >Maak actueel</ActionButton>
                    <slot name="customcommands" :bulkAction="dt.bulkAction" :selectedCount="dt.selectedCount">
                    </slot>
                </slot>
            </template>
        </CommandPane> 

    </div>
</template>

<script setup>
import Checkbox from '@controls/check/Checkbox'
import IconMenu from "@controls/menu/IconMenu.vue";
import Icon from '@controls/icons/Icon'
import TextField from '@controls/input/TextField'
import CommandPane from '@datatable/components/CommandPane'
import ActionButton from '@controls/buttons/ActionButton'

import DateTimeColumn from '@datatable/columns/DateTime'
import TimestampColumn from '@datatable/columns/Timestamp'
import ExpiryDateColumn from '@datatable/columns/ExpiryDate'
import DaysFromNow from '@datatable/columns/DaysFromNow'
import LocationColumn from '@datatable/columns/Location'

import BoolYesNoColumn from '@datatable/columns/BoolYesNo'
import PriorityColumn from '@datatable/columns/Priority'

import HourMinuteColumn from '@datatable/columns/HourMinute'
import DateColumn from '@datatable/columns/Date'
import EmailColumn from '@datatable/columns/Email'
import PhoneColumn from '@datatable/columns/Phone'
import AmountColumn from '@datatable/columns/Amount'
import PercentageColumn from '@datatable/columns/Percentage'
import NoteColumn from '@datatable/columns/Note'
import AttachmentColumn from '@datatable/columns/Attachment'
import FlagMenu from "@controls/menu/FlagMenu.vue";        
import FlagColumn  from '@datatable/columns/Flag'

import string from '@lib/string'
import {computed} from 'vue'
import dlg from '@app/dlg'

    const props = defineProps({
        dt: {
            type: [Object]
        },
    })

    function coalesce(item, field, alternateText) {
        return string.coalesce(item[field], alternateText);
    }
    function columnSlotName(item) {
        return `item.${item.value}`
    }
    function headerSlotName(item) {
        return `header.${item.value}`
    }

    var columnHeaders = computed({
        get() {
            return (props.dt.headers||[]).filter( (header) => header.value != "cmd")
        }
    })

    var compClass = computed({
        get() {
            if (props.dt.noPager) {
                return "no-pager";
            }
            return ""
        }
    })

    // When an open action is specified, either: 
    // - open the dialog with the item data as parameters
    // - open the dialog and get the parameters from a specified function
    // - call a custom function to open the dialog
    function onOpen(item, header) {
        if (header.type !== "open") {
            return;
        }
        if (typeof(header.open) == 'function') {
            header.open(item, header);
            return;
        }
        var params = item;
        if (header.fnParamsOpen && typeof(header.fnParamsOpen) == 'function') {
            params = header.fnParamsOpen(item);
        }
        var modelOpen = header.open || props.dt.modelName;
        if (!modelOpen) {
            console.error("Invalid open action - type is not specified.");
            return;
        }
        dlg.open(modelOpen, params);
    }

    function onNote(item, header)  {
        dlg.open('note', item, {
            id_optimit_type: header.id_optimit_type||item.id_optimit_type, 
            rightsModelName: header.noteType
        })
    }
    // This functionallity is removed from optimit
    function onAttachment(item, header)  {
        dlg.open('attachment', item, {
            id_optimit_type: header.id_optimit_type||item.id_optimit_type, 
            rightsModelName: header.attachmentType
        })
    }

</script>
<template> 
    <v-list-item v-if="isAccessible" :disabled="!isAuthorized" :to="compTo" :exact="exact" :class="{'opacity-50': compDisabled}" :title="compTitle" @click="onClick">
        <v-list-item-action v-if="!noIcon" class="mr-1">
            <Icon :color="color" v-if="compIcon" :type="compIcon" small></Icon>
        </v-list-item-action>
        <v-list-item-content >
            <v-list-item-title>
                <slot :title="compText">{{compText}}</slot>
            </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="useCount">
            <NumberChip :count="count"></NumberChip>
        </v-list-item-action>
    </v-list-item>
</template>

<script setup>

import routing from '@app/routing'
import {computed} from 'vue'
import noty from '@shared/lib/noty'
import Icon from '@controls/icons/Icon'
import NumberChip from "@controls/widgets/NumberChip";

/*

    Usage: 
    
        import RouteMenuItem from "@controls/menu/NamedRouteMenuItem.vue";

        <v-list dense>
            <RouteMenuItem route="relations.overview">Overzicht</RouteMenuItem> 
            <RouteMenuItem route="relations.archive">Archief</RouteMenuItem> 
        </v-list>              
        
*/

const props = defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
    icon: {
        type: [String]
    },
    route: {
        type: [String],
        default: ""
    },
    params: {
        type: [Object],
    },
    color: {
        type: [String],
        default: ""
    },
    useCount:{
        type: Boolean,
        default: false,
    },
    count: {
        type: [String, Number],
        default: ""
    },
    noIcon: {
        type: [Boolean],
        default: false
    },
    // Show the text of the root menu - e.g. 'Instellingen' i.s.o. 'Units'.
    showRootText: {
        type: [Boolean],
        default: false
    },
    exact: {
        type: [Boolean],
        default: true
    }
})

// Is the current user fully authorized to access the given route? 
const isAuthorized = computed({
    get() {
        return routing.isUserAuthorizedFor(props.route);
    }
});

// When the current company does not have access to the required module, the 
// route should not be shown at all 
const isAccessible = computed({
    get() {
        return routing.hasRequiredModule(props.route);
    }
});

const compTo = computed({
    get() {
        if (!isAuthorized.value) {
            return null;
        }
        return routing.to(props.route, props.params);
    }
});


const compDisabled = computed({
    get() {
        if (props.disabled) {
            return true;
        }
        if (!isAuthorized.value) {
            return true;
        }
        return false;
    }
});

const compTitle = computed({
    get() {
        if (props.disabled) {
            return true;
        }
        if (!isAuthorized.value) {
            return "U heeft geen rechten dit scherm te laden.";
        }
        return null;
    }
});
const compText = computed({
    get() {
        return routing.textFor(props.route);
    }
});

const compIcon = computed({
    get() {
        if (props.noIcon) {
            return null;
        }
        if (props.icon) {
            return props.icon;
        }
        return routing.iconFor(props.route);
    }
});

function onClick() {
    if (!isAuthorized.value) {
         noty.alert("U heeft geen rechten deze actie uit te voeren");
    }
}

</script>
<template>
    <div>
        <Datatable :dt="dt" class="full-height">
            <template v-slot:menu ="{ item }">
                <ActionMenuItem action="salesinvoice.open" @click="dlg.open('salesinvoice', item)"></ActionMenuItem>
            </template>

            <template v-slot:item.inv_status ="{ item }">

                <span class="cursor-pointer" @click="dlg.open('salesinvoice', item)">            
                <StatusChip type="salesinvoice" :status="item.inv_status" :substatus="subStatus(item)">{{statusRep(item)}}</StatusChip>
            </span>
        </template>
        </Datatable>
    </div>
</template>

<script setup>
    import useDataTable from '@app/useDataTable'
    import Datatable from '@datatable/Datatable'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import { salesinvoices as api } from '@app/api'
    import string from '@lib/string'
    import dlg from '@app/dlg'
    import StatusChip from "@controls/widgets/StatusChip.vue";
    import {date} from '@lib/date'
    const props = defineProps({
        model: {
            type: [Object]
        },
    });

    var fmtName = function(a,b,item) {
        return string.concat(" ", item.prs_firstname, item.prs_infix, item.prs_lastname);  
    }

    var headers = [
    { type: 'cmd'},
        { type: 'flag', flagType: 'salesinvoice_flag'},
        { type: 'note', noteType: 'salesinvoice_note'},
        { text: "Status",                   value: "inv_status", },
        { text: "Factuurnummer",            value: "inv_number", type: 'open', fmt: (v, item) => ((v)? v : '-')} ,
        { visible: false, text: "Klant",                    value: "rel_name"} ,
        {  text: "Factuur titel",            value: "inv_name",       cellClass: "max-col-width" },
        { visible: false, text: "Referentie",               value: "inv_reference", fmt: (v, item) => ((v)? v : '-')} ,
        { text: "Factuurdatum",             value: "inv_date",    type: 'date', sort: 'desc'   },
        { visible: false, text: "Vervaldatum", value: "inv_exp_date", type: 'date'},
        { text: "Excl BTW",                 value: "inv_sub_total",     type: 'amount'     },
        { text: "BTW",                      value: "inv_vat_total",      type: 'amount'     },
        { text: "Incl BTW",                 value: "inv_total",  type: 'amount'     },
        { visible: false,  text: "Betaald",                  value: "inv_total_paid",     type: 'amount'     },
        { visible: false,  text: "Openstaand",               value: "inv_amount_open",     type: 'amount'     },
        { visible: false,  text: "1e herinnering",           value: "reminder1_date",     type: 'date'     , emptyText: '-'},
        { visible: false,  text: "2e herinnering",           value: "reminder2_date",     type: 'date'     , emptyText: '-'},
        { visible: false,  text: "In gebrekestelling",       value: "defaultnotice_date",     type: 'date' , emptyText: '-'},

        { visible: false, text: "Gewijzigd",                value: "updated_at",    type: 'datetime',   },
		{ visible: false, text: "Gewijzigd door",           value: "updated_by",                        },
		{ visible: false, text: "Aangemaakt",               value: "created_at",    type: 'datetime',   },
		{ visible: false, text: "Aangemaakt door",          value: "created_by",                        },
		{ visible: false, text: "Boekhoudnummer",           value: "accountancy_number",                },
    ]

    var dt = useDataTable("dt_relation_salesinvoices", "salesinvoice", api, headers, {
        itemName: {
            prefix: 'De',
            single: 'verkoopfactuur',
            plural: 'verkoopfacturen',
        }, parent: {
            id_relation: props.model.id
        },
        canArchive: false
    })

    // Show the pdf
    function onPdf(item) {
        dlg.pdf( async () => api.getPdf(item.id));
    }

    function statusRep(item) {
        let status = item?.inv_status;
        switch (string.lower(status)) {
            case "new"     : return "Nieuw";
            case "concept" : return "Concept";
            case "open"    : 
                if (item.defaultnotice_date) {
                    return "Ingebreke";
                }
                if (item.reminder2_date) {
                    return "Herinnering 2";
                }
                if (item.reminder1_date) {
                    return "Herinnering 1";
                }
                if (item.inv_total_paid) {
                    return "Deelbetaling";
                }
                if (date.isInPast(item.inv_exp_date)) {
                    return "Vervallen";
                }
                return "Open";
            case "paid"    : return "Betaald";
        }
        return "-";
    }
    /**
     * When the status is open and a reminder is sent, reflect the correct substatus.  
     * 
     */
    function subStatus(item) {
        let status = item?.inv_status;
        if (string.lower(status) != "open") {
            return null;
        }
        let strSubStatus = null;
        if (date.isInPast(item.inv_exp_date)) {
            strSubStatus = "expired";
        }
        if (item.reminder1_date) {
            strSubStatus = "reminder1";
        }
        if (item.reminder2_date) {
            strSubStatus = "reminder2";
        }
        if (item.defaultnotice_date) {
            strSubStatus = "defaultnotice";
        }
        if (item.inv_total_paid) {
            return "wrongly-paid";
        }

        return strSubStatus;
    }

</script>
import clsSettings from '@cls/clsSettings'
import {settings as api} from '@app/api'

var modelName="document"
class clsDocumentSettings extends clsSettings {

    /**
     * clone the current setting.
     * load fresh data from the server.
     * autoLoad: load the data automagically
     * noDestroy: prevent object being cleaned up on unmount.
     *            Actually, can not think for a use case.
     */
    clone(autoLoad, noDestroy) {
        var obj = new clsDocumentSettings();

        if (!noDestroy) {
            obj.registerDestroyOnUnmount();            
        }
        if (autoLoad) {
            obj.load();
        }
    
        return obj;
    }
    
    isLogoUploading                  = false;
    isLetterUploading                = false;
    
    url_letter                       = null;
    url_logo                         = null;
    id_file_document_letter          = null;
    letter_show                      = null;
    id_file_document_logo            = null;
    logo_show                        = null;
    logo_margin_left                 = null;
    logo_margin_top                  = null;
    logo_width                       = null;
    company_info_show                = null;
    company_info_margin_right   = null;
    company_info_margin_top     = null;
    font                         = null;
    fontsize                         = null;
    color_custom                 = null;
    color_accent                 = null;
    recipient_margin_left            = null;
    recipient_margin_top             = null;
    margin_left                      = null;
    margin_right                     = null;
    margin_top                       = null;
    margin_bottom                    = null;
    content_margin_top               = null;
    id_theme                            = null;
    company                          = [];

    constructor() {
        super({modelName: modelName});
    }

    removeCompanyLine(lineRemove) {
        this.company = this.company.filter( (line) => line != lineRemove);
    }
    addLine(cols) {
        this.company.push( {
            cols : cols?cols:1,
            text1: "",
            text1_style: "",
            text2: "",
            text2_style: "",
            //sequence:
        });
    }
    async onLogoSelected(files) {
        this.isLogoUploading = true;
        try {
            var result = await api.uploadFileList(files, {
                url: 'upload/document/logo', maxFiles: 1
            })
            
            this.id_file_document_logo = result.data.id;
            this.url_logo = result.data.url;
            this.logo_show = true;
        } 
        finally {
            this.isLogoUploading = false;
        }
        return result;
    }
    async onLetterSelected(files) {
        this.isLetterUploading = true;
        try {
            var result = await api.uploadFileList(files, {
                url: 'upload/document/letter', maxFiles: 1
            })
            
            this.id_file_document_letter = result.data.id;
            this.url_letter = result.data.url;
            this.letter_show = true;
        } 
        finally {
            this.isLetterUploading = false;
        }
        return result;
    }
    toggleStyle(line, n) {
        console.log('toggleStyle.', line, n)
        if (n == 2) {
            line.text2_style = line.text2_style ? "" : "b";
        }
        else {
            line.text1_style = line.text1_style ? "" : "b";
        }
    }

    fill(data) {
        data = data ||{};
        if ( !data.logo_width) {
            data.logo_width = 60;
        }
        this.url_letter                     = data.url_letter;
        this.url_logo                       = data.url_logo;        
        this.id_file_document_letter        = data.id_file_document_letter;
        this.letter_show                    = data.letter_show;
        this.id_file_document_logo          = data.id_file_document_logo;
        this.logo_show                      = data.logo_show;
        this.logo_margin_left               = data.logo_margin_left;
        this.logo_margin_top                = data.logo_margin_top;
        this.logo_width                     = data.logo_width;
        this.company_info_show              = data.company_info_show;
        this.company_info_margin_right      = data.company_info_margin_right;
        this.company_info_margin_top        = data.company_info_margin_top;
        this.font                           = data.font;
        this.fontsize                       = data.fontsize;
        this.color_custom                   = data.color_custom;
        this.color_accent                   = data.color_accent;
        this.recipient_margin_left          = data.recipient_margin_left;
        this.recipient_margin_top           = data.recipient_margin_top;
        this.margin_left                    = data.margin_left;
        this.margin_right                   = data.margin_right;
        this.margin_top                     = data.margin_top;
        this.margin_bottom                  = data.margin_bottom;
        this.content_margin_top             = data.content_margin_top;
        this.id_theme                       = data.id_theme || 1;

        this.company                        = data.company;

    }

    toJSON() {
        return {
            url_letter                     : this.url_letter,
            url_logo                       : this.url_logo,        
            id_file_document_letter        : this.id_file_document_letter,
            letter_show                    : this.letter_show,
            id_file_document_logo          : this.id_file_document_logo,
            logo_show                      : this.logo_show,
            logo_margin_left               : this.logo_margin_left,
            logo_margin_top                : this.logo_margin_top,
            logo_width                     : this.logo_width,
            company_info_show              : this.company_info_show,
            company_info_margin_right : this.company_info_margin_right,
            company_info_margin_top   : this.company_info_margin_top,
            font                       : this.font,
            fontsize                       : this.fontsize,
            color_custom               : this.color_custom,
            color_accent               : this.color_accent,
            recipient_margin_left          : this.recipient_margin_left,
            recipient_margin_top           : this.recipient_margin_top,
            margin_left                    : this.margin_left,
            margin_right                   : this.margin_right,
            margin_top                     : this.margin_top,
            margin_bottom                  : this.margin_bottom,
            content_margin_top             : this.content_margin_top,
            id_theme                          : this.id_theme,
            company                        : this.company 
        };
    }

}

export default clsDocumentSettings;
